export const getDate= (past: Date) => {
    const today = new Date();
    let diff = Math.floor(today.getTime() - past.getTime());
    let minute = 1000 * 60 ;

    let minutes = Math.floor(diff/minute);
    let hours = Math.floor(minutes/60);
    let days = Math.floor(hours/24);
    let months = Math.floor(days/31);
    let years = Math.floor(months/12);

    if(years > 0) return years + " Years ago";
    if(months > 0) return months + " Months ago";
    if(days > 0) return days + " Days ago";
    //TODO:
    // if(hours > 0) return hours + " Hours ago";
    // if(minutes > 0) return minutes + " Minutes ago"
    // return 'Less than a minute ago';
    return "Today";
  };