import { Card, Menu } from "antd";
import React from "react";
import DropDown from "../input/DropDown";
import "./SelectDropdowns.css";
import type { MenuProps } from "antd";
import { homePage } from "../../../shared/enums/homePageEnum";
import {
  HomePageDropdown,
  marketOfferingResponse,
  offeringPortfolioResponse,
  offeringResponse,
} from "../../../shared/interfaces/HomePageInterfaces";
import {
  fetchAPI,
  getErrorResponseStatus,
} from "../../../shared/custom-hooks/fetchAPI";
import ErrorPage from "../errorPage/ErrorPage";
import { Endpoints } from "../../../shared/enums/endpoints";

const SelectDropdowns = ({
  offeringPortfolio,
  setOfferingPortfolio,
  offering,
  offeringPortfolioCode,
  offeringCode,
  setOffering,
  offeringPortfolioDetails,
  setOfferingPortfolioDetails,
  marketOffering,
  offeringDetails,
  setOfferingDetails,
  setMarketOffering,
  setMarketOfferingDetails,
  marketOfferingDetails,
  searchMercuryApiData,
  setSearchMercuryApiData
}: HomePageDropdown) => {
  const [offeringPortfolioMenuItems, setOfferingPortfolioMenuItems] =
    React.useState<offeringPortfolioResponse[]>([]);
  const [offeringMenuItems, setOfferingMenuItems] = React.useState<
    offeringResponse[]
  >([]);
  const [marketOfferingMenuItems, setMarketOfferingMenuItems] = React.useState<
    marketOfferingResponse[]
  >([]);
  const [offerDetailsUpdated, setOfferDetailsUpdated] = React.useState<boolean>(false);
  const [OfferingPortfolioLoading, setOfferingPortfolioLoading] =
    React.useState(false);
  const[offeringPortfolioObj, setOfferingPortfolioObj] = React.useState<string>("");
  const [OfferingLoading, setOfferingLoading] = React.useState(false);
  const [MarketOfferingLoading, setMarketOfferingLoading] =
    React.useState(false);
  let isFromWelcome =  sessionStorage.getItem("loadFromWelcome");
  
  const handleofferingPortfolio: MenuProps["onClick"] = (e) => {
    const selectedItem: offeringPortfolioResponse[] =
      offeringPortfolioMenuItems.filter((obj) => {
        return obj?.key.toString() === e.key;
      });
    if(offeringPortfolio!==selectedItem[0]?.label){
      setOfferingMenuItems([]);
      setMarketOfferingMenuItems([]);
    }
    sessionStorage.setItem("loadOfferingFromWelcome", "false");
    setOfferingPortfolio(selectedItem[0]?.label);
    setOfferingPortfolioDetails && setOfferingPortfolioDetails(selectedItem);
    setOffering("");
    if(selectedItem[0].op_leader_user != null){
      setOfferingPortfolioObj(selectedItem[0].op_leader_user?.first_name + " " + selectedItem[0].op_leader_user?.last_name);
    }
    else {
      setOfferingPortfolioObj("");
    }
      
    setOfferingDetails && setOfferingDetails([]);
    setMarketOffering("");
    setMarketOfferingDetails([]);
  };
  const handleOffering: MenuProps["onClick"] = (e) => {
    const selectedItem: offeringResponse[] = offeringMenuItems.filter((obj) => {
      return obj?.key.toString() === e.key;
    });
    sessionStorage.setItem("loadOfferingFromWelcome", "false");
    if(offering!==selectedItem[0]?.label)
      setMarketOfferingMenuItems([]);
    setOfferDetailsUpdated(true);
    setOffering(selectedItem[0]?.label);
    setOfferingDetails && setOfferingDetails(selectedItem);
    setMarketOffering("");
    setMarketOfferingDetails([]);
  };

  const handleMarketOffering: MenuProps["onClick"] = (e) => {
    const selectedItem: marketOfferingResponse[] =
      marketOfferingMenuItems.filter((obj) => {
        return obj?.key.toString() === e.key;
      });
    setMarketOffering(selectedItem[0]?.label);
    setMarketOfferingDetails(selectedItem);
  };

  React.useEffect(() => {
    fetchOfferingPortfolioApiResponse();
  }, []);

  React.useEffect(() => {
    if(isFromWelcome != null && isFromWelcome == "true"){
      setOfferDetailsUpdated(true);
    }
    
    if (offeringPortfolio) fetchOfferingApiResponse();
  }, [offeringPortfolio]);

  React.useEffect(() => {
    if (offering) fetchMarketOfferingApiResponse();
  }, [offering]);

  const offeringPortfolioDropdownMenu = (
    <Menu
      onClick={handleofferingPortfolio}
      items={offeringPortfolioMenuItems}
      data-testid="op-menu"
    />
  );

  const offeringDropdownMenu = (
    <Menu onClick={handleOffering} items={offeringMenuItems} />
  );
  const marketOfferingDropdownMenu = (
    <Menu onClick={handleMarketOffering} items={marketOfferingMenuItems} />
  );

  const fetchOfferingPortfolioApiResponse = async () => {
    setOfferingPortfolioLoading(true);
    const getOfferingPortfolioData: any = await fetchAPI(
      `${process.env.REACT_APP_BASE_URL}${Endpoints.offeringPortfolio}`,
      {
        method: "GET",
        headers: {}
      }
    );
    if (!getOfferingPortfolioData?.errMsg) {
      const mappedArr = getOfferingPortfolioData?.responseData.data.map((v:any)=>({...v, id:"DD_OfferingPortfolio"}));
      setOfferingPortfolioMenuItems(mappedArr);
    } else {
      console.log("Res Status", getOfferingPortfolioData?.responseStatus);

      // <ErrorPage
      //   responseStatus={getErrorResponseStatus(
      //     getOfferingPortfolioData.responseStatus
      //   )}
      // />;
    }
    setOfferingPortfolioLoading(false);
  };

  const fetchOfferingApiResponse = async () => {
    setOfferingLoading(true);
    const getOfferingData: any = await fetchAPI(
      `${process.env.REACT_APP_BASE_URL}${Endpoints.offering}`,
      {
        method: "GET",

        queryParam:
          "op_code=" +
          (offeringPortfolioDetails[0]?.op_code || offeringPortfolioCode),
          headers: {}
      }
    );
    if (!getOfferingData?.errMsg) {
      const mappedArr = getOfferingData?.responseData.data.map((v:any)=>({...v, id:"DD_Offering"}));
      setOfferingMenuItems(mappedArr);
    } else {
      console.log("Res Status", getOfferingData?.responseStatus);

      // <ErrorPage
      //   responseStatus={getErrorResponseStatus(getOfferingData.responseStatus)}
      // />;
    }
    setOfferingLoading(false);
  };

  const fetchMarketOfferingApiResponse = async () => {
    setMarketOfferingLoading(true);
    const getMarketOfferingData: any = await fetchAPI(
      `${process.env.REACT_APP_BASE_URL}${Endpoints.marketOffering}`,
      {
        method: "GET",
        queryParam:
          "offering_code=" +
          (offeringDetails[0]?.offering_code || offeringCode),
          headers: {}
      }
    );
    if (!getMarketOfferingData?.errMsg) {
      const mappedArr = getMarketOfferingData?.responseData.data.map((v:any)=>({...v, id:"DD_MarketOffering"}));
      setMarketOfferingMenuItems(mappedArr);

    } else {
      console.log("Res Status", getMarketOfferingData?.responseStatus);

      // <ErrorPage
      //   responseStatus={getErrorResponseStatus(getOfferingData.responseStatus)}
      // />;
    }
    setMarketOfferingLoading(false);
  };

  return (
    <div className="site-card">
      <Card className="select-dropdowns-card" bordered={false}>
        <span className="dropdown-div">
          <DropDown
            value={offeringPortfolio || "Select Offering Portfolio"}
            placeholder={homePage.offeringPortfolioDropdown}
            menu={offeringPortfolioDropdownMenu}
            loading={OfferingPortfolioLoading}
            isSubLabel={true}
            subLabel={ offeringPortfolioObj != "" ?  homePage.offeringPortfolioLead : offeringPortfolioDetails.op_lead != null ?
            homePage.offeringPortfolioLead : ""}
            subLabelName = {
              offeringPortfolioObj != "" ? offeringPortfolioObj : offeringPortfolioDetails.op_lead != null ?
              offeringPortfolioDetails?.op_lead?.first_name + " " + offeringPortfolioDetails?.op_lead?.last_name : ""
            }
          />
        </span>        
        <span className="dropdown-div">
          <DropDown
            value={offering || "Select Offering "}
            placeholder={homePage.offeringDropdown}
            menu={offeringDropdownMenu}
            isSubLabel={true}
            loading={OfferingLoading}
            isDisabled={!offeringPortfolio}
            subLabel={offering ? homePage.offeringLead : ""}
            subLabelName={ offering ?
              offeringDetails.length == undefined ? offeringDetails.offering_lead_user?.first_name + " " + offeringDetails.offering_lead_user?.last_name :
              offeringDetails[0]?.offering_lead_user?.first_name + " " + offeringDetails[0]?.offering_lead_user?.last_name : ""
            }
            // subLabelName={
            //   offerDetailsUpdated == false ?
            //   offeringDetails
            //     ? offering
            //       ? offeringDetails.offering_lead_user?.first_name +
            //         " " +
            //         offeringDetails.offering_lead_user?.last_name
            //       : ""
            //     : offering
            //     ? searchMercuryApiData?.offering?.offering_lead_user
            //         ?.first_name +
            //       " " +
            //       searchMercuryApiData?.offering?.offering_lead_user?.last_name
            //     : " "
            //     :
            //     offeringDetails?.length
            //     ? offering
            //       ? offeringDetails[0]?.offering_lead_user?.first_name +
            //         " " +
            //         offeringDetails[0]?.offering_lead_user?.last_name
            //       : ""
            //     : offering
            //     ? searchMercuryApiData?.offering?.offering_lead_user
            //         ?.first_name +
            //       " " +
            //       searchMercuryApiData?.offering?.offering_lead_user?.last_name
            //     : " "
            // }
          />
        </span>
        <span className="dropdown-div">
          <DropDown
            value={marketOffering || "Select Market Offering "}
            placeholder={homePage.marketOfferingDropdown}
            menu={marketOfferingDropdownMenu}
            isSubLabel={true}
            loading={MarketOfferingLoading}
            isDisabled={!offering}
            subLabel={marketOffering ? homePage.marketOfferingLead : ""}
            subLabelName={
              marketOfferingDetails?.length
                ? marketOffering
                  ? marketOfferingDetails[0]?.mo_lead_user?.first_name +
                    " " +
                    marketOfferingDetails[0]?.mo_lead_user?.last_name
                  : ""
                : marketOffering
                ? searchMercuryApiData?.mo?.mo_lead_user?.first_name +
                  " " +
                  searchMercuryApiData?.mo?.mo_lead_user?.last_name
                : ""
            }
          />
        </span>
      </Card>
    </div>
  );
};

export default SelectDropdowns;
