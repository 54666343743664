import React from "react";
import { notification } from "antd";

const SuccessMessage = ( messageContent: string) => {

  notification.success({
    message: messageContent,
    duration: 2,
    closeIcon : (<span></span>),
    style: {
      border: "2px solid #B7EB8F",
      float: "right",
      fontFamily: "Roboto",
      borderRadius: 2,
      fontSize: 14,
      backgroundColor: "#F6FFED",
      width: "100%",
      padding: "0.5rem 0 0.5rem 0.5rem",
      marginRight: "1rem",
      marginTop: "3rem",
    },
  })
};

export default SuccessMessage;
