import React from "react";
import "./Guidance.css";
import { iGuidanceFooterProps } from "../../../shared/interfaces/guidanceInterface";
import Divider from "antd/lib/divider";
import { guidanceEnums } from "../../../shared/enums/guidanceEnum";

const GuidanceFooter = ({ selectedMO }: iGuidanceFooterProps) => {
  return (
    <div className="footer" data-testid="guidance-footer">
      <Divider className="guidance-divider" />
      <div className="leaders">
        {selectedMO?.service_excellence_lead_user?.last_name ? (
          <p className="leader-content">
            <span className="leaders-heading">
              {guidanceEnums.saleExcellence}
            </span>
            :{" "}
            {(selectedMO?.service_excellence_lead_user?.first_name || "") +
              " " +
              (selectedMO?.service_excellence_lead_user?.last_name || "")}
          </p>
        ) : (
          ""
        )}
        {selectedMO?.delivery_excellence_lead_user?.last_name ? (
          <p className="leader-content">
            <span className="leaders-heading">
              {guidanceEnums.deliveryExcellence}
            </span>
            :{" "}
            {(selectedMO?.delivery_excellence_lead_user?.first_name || "") +
              " " +
              (selectedMO?.delivery_excellence_lead_user?.last_name || "")}
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default GuidanceFooter;
