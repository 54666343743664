import React from "react";
import { Typography } from "antd";
import { LabelFieldProps } from "../../../shared/interfaces/common";
const { Text } = Typography;

const Label = ({ placeholder, ...rest }: LabelFieldProps) => {
  return (
    <Text data-testid="label-test" {...rest}>
      {placeholder}
    </Text>
  );
};

export default Label;
