import { Tabs } from "antd";
import { user } from "../../shared/interfaces/common";
import Ribbon from "../common/ribbon/Ribbon";
import UserManagement from "./UserManagement/UserManagement";
import "./index.css";
import Offering from "./offering/OfferingManagement";
import MarketOfferingManagement from "./marketOffering/MarketOfferingManagement";
import OfferingManagement from "./offering/OfferingManagement";
import OfferingPortfolioManagement from "./offeringPortfolio/OfferingPortfolioManagement";
import ViewFeedback from "./Feedback/ViewFeedback";
import FileUpload from "./fileUpload/FileUpload";
import CommonButton from "../common/input/CommonButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";


const Admin = ({ userEmail, userName }: user) => {
  const navigate = useNavigate();
	const handleClick = () => navigate("/");
  sessionStorage.setItem("loadFromWelcome", "false");
	sessionStorage.setItem("loadOfferingFromWelcome", "false");
  sessionStorage.setItem("searchJOFromWelcome", "false");

  useEffect(() =>{
    if(sessionStorage.getItem("adminCall")  == "false"){
      window.analyticsDataLayer = {
        pageInfo:{
          pageName:"Admin",
          userID: sessionStorage.getItem('userName'),
          applicationName: "Strategic Pricing Portal",
          userType: sessionStorage.getItem("userType"),
        }
      };
      if(typeof window._satellite !== 'undefined') {
        window._satellite.track('pageView');
      }
      sessionStorage.setItem("adminCall", "true");
      sessionStorage.setItem("dashboardCall", "false");
		  sessionStorage.setItem("userManagementCall", "false");
    }
  })
  
  const items = [
    // {
    //   label: "Home",
    //   key: "1",
    //   children: "Home",
    //   disabled: true,
    // },
    // {
    //   label: "Pricing Dashboard",
    //   key: "2",
    //   children: "Dashboard",
    //   disabled: true,
    // },
    {
      label: "User Management",
      key: "3",
      children: <UserManagement />,
    },
    {
      label: "Offering Portfolio",
      key: "4",
      children: <OfferingPortfolioManagement />,
    },
    {
      label: "Offering",
      key: "5",
      children: <OfferingManagement />,
    },
    {
      label: "Market Offering",
      key: "6",
      children: <MarketOfferingManagement />,
    },
    {
      label: "Feedback",
      key: "7",
      children: <ViewFeedback />,
    },
    {
      label: "Upload",
      key: "8",
      children: <FileUpload />,
    },
  ];
  return (
		<div style={{ background: "#ececec", minHeight: "100vh", height: "100%" }}>
			<Ribbon userEmail={userEmail} />
			<div className="ribbon-tabs">
				<CommonButton
					className="custom-home-button"
					value={"Go to Pricing Dashboard"}
					onClick={handleClick}
				/>
				<Tabs
					items={items}
					className="dashboard-tabs-main"
					defaultActiveKey="3"
          destroyInactiveTabPane
				/>
			</div>
		</div>
	);
};

export default Admin;
