import "./CommonChart.css";
import DropDown from "../input/DropDown";
import { Button } from "antd";
import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  Legend,
} from "chart.js";
import { Scatter, Chart } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { Menu, MenuProps } from "antd";
import { RphChartProps } from "../../../shared/interfaces/chartInterface";
import { chartLegendColors } from "../../../shared/enums/chartEnums";
import DropDownRphXaxis from "./DropDownRphXaxis";
import { round } from "../../../shared/utils/graphUtil";

ChartJS.register(
  LinearScale,
  PointElement,
  CategoryScale,
  LineElement,
  Tooltip,
  Legend,
  annotationPlugin,
  LineController
);

export function RphChart({
  xlabel,
  ylabel,
  apiData,
  labelValues,
  timeFrameValue,
  setTimeFrameValue,
  rphXaxisValue,
  setRphXaxisValue,
  nsrValueOptions,
  nsrRangeValue,
  setNsrRangeValue,
  setHighLightValue,
  highlightValue,
  searching
}: RphChartProps) {
  const { lowerQuartileValue, upperQuartileValue, centerQuartileLineValue } =
    labelValues;
  const [chartData, setChartData] = useState<any>();
  const [dataMapped, setDataMapped] = useState<boolean>(false);
  const [checkSearch, setCheckSearch] = useState<boolean>(searching);
  const[labelsYaxis, setLabelsYaxis] = useState<Array<string>>([]);
  //TODO:add type
  let colorindex = -1;
  if (nsrRangeValue !== "All") {
    colorindex = Object.keys(nsrValueOptions).findIndex(
      (p) => p == nsrRangeValue
    );
  }

  const apiDataWithColors = apiData.datasets;

  //Regression line formula.
  const findLineByLeastSquares = (values_x: any, values_y: any) => {
    var sum_x = 0;
    var sum_y = 0;
    var sum_xy = 0;
    var sum_xx = 0;
    var count = 0;

    /*
     * We'll use those variables for faster read/write access.
     */
    var x = 0;
    var y = 0;
    var values_length = values_x.length;

    if (values_length != values_y.length) {
      throw new Error('The parameters values_x and values_y need to have same size!');
    }

    if (values_length === 0) {
      return [ [], [] ];
    }

    /*
     * Calculate the sum for each of the parts necessary.
     */
    let v = 0;
    for (v = 0; v < values_length; v++) {
      x = values_x[v];
      y = values_y[v];
      sum_x += x;
      sum_y += y;
      sum_xx += x*x;
      sum_xy += x*y;
      count++;
      // console.log("x ", v, " " , x);
      // console.log("y ", v, " " , y);
      // console.log("sum_xx ", v, " " , sum_xx);
      // console.log("sum_xy ", v, " " , sum_xy);
    }

    // console.log("sum_xx sum value: ", sum_xx);
    // console.log("sum_xy sum value: " , sum_xy);
    // console.log("sum of x: ", sum_x);
    // console.log("sum of y: ", sum_y);
    // console.log("count: ", count);

    /*
     * Calculate m and b for the formular:
     * y = x * m + b
     */
    var m = (count*sum_xy - sum_x*sum_y) / (count*sum_xx - sum_x*sum_x);
    // console.log("slope", m);
    var b = (sum_y/count) - (m*sum_x)/count;
    //console.log("intercept", b);



    /*
     * We will make the x and y result line now
     */
    var result_values_x = [];
    var result_values_y = [];

    for (v = 0; v < values_length; v++) {
      x = values_x[v];
      y = x * m + b;
      result_values_x.push(x);
      result_values_y.push(y);
    }

    return [result_values_x, result_values_y];
  }

  let x_cordinates = [];
  let y_cordinates = [];
  let y_data: Array<any> = [];
  const lineLabels: Array<string> = [];
  let maxVal = 0;
  // const scatterData = apiData.datasets;
  if (sessionStorage.getItem("dataMapped") == "false") {
    sessionStorage.setItem('dataMapped','true');
    if (x_cordinates?.length == 0 || y_cordinates?.length == 0) {
      for(var i = 0;i<apiData.datasets.length; i++){
        for(var j = 0; j < apiData.datasets[i].data.length; j++) {
          if(apiData.datasets[i].data.length > 0){
            x_cordinates.push(apiData.datasets[i].data[j].x);
            y_cordinates.push(apiData.datasets[i].data[j].y);
          }
        }
      }

      if(apiData.datasets.length > 0){
        let xyLinevalue = findLineByLeastSquares(x_cordinates, y_cordinates);
        //console.log("regression values: ", xyLinevalue)
        let i, isZero = true;
        for(i=0; i<xyLinevalue[1].length; i++){
          y_data.push({x:xyLinevalue[0][i], y: xyLinevalue[1][i]})
        }
      }
    }

    apiDataWithColors.push({
      xAxisID:'x2', type: 'line', label: 'Line Dataset', pointRadius: 1.5, data: y_data, borderColor: 'grey',});
    //data: y_data, color:'transparent' , backgroundColor: 'rgba(128, 128, 128, 0.1)', borderColor: 'grey'
    let scatterData:any;
    scatterData = apiData.datasets.map((data: any, index: number) => {
      return {...data};
    });

    //  console.log(apiDataWithColors);
    const apiDataForMultiChart : any = {
      datasets : [{
        backgroundColor:
          colorindex === -1
            ? chartLegendColors[0]
            : chartLegendColors[colorindex],
        type:'scatter',
        data:scatterData
      }]
    }
  }
  apiData.datasets.map((arr: any) => {
    maxVal = Math.max(maxVal, Math.max(...arr?.data?.map((o: any) => o.y)));
  });

  const rphYAxisOptions =
    maxVal > 700
      ? {
        //beginAtZero: true,
        max: 700,
        min: 0,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return "$" + value;
          },
        },
        title: {
          display: true,
          align: "center",
          text: ylabel,
          color: "rgb(0,0,0)",
          font: {
            family: "Open Sans",
            size: 12,
            style: "normal",
            weight: 600,
          },
          padding: 4,
        },
        grid: {
          borderColor: "#D9D9D9",
          color: "#D9D9D9",
          borderWidth: 1,
          lineWidth: 1,
          display: true,
        },
      }
      : {
        //beginAtZero: true,
        min: 0,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return "$" + value;
          },
        },
        title: {
          display: true,
          align: "center",
          text: ylabel,
          color: "rgb(0,0,0)",
          font: {
            family: "Open Sans",
            size: 12,
            style: "normal",
            weight: 600,
          },
          padding: 4,
        },
        grid: {
          borderColor: "#D9D9D9",
          color: "#D9D9D9",
          borderWidth: 1,
          lineWidth: 1,
          display: true,
        },
      };
  //Chart options and plugins
  const noDataoptionsions: any = {
    //maintainAspectRatio: false,
    scales: {
      //x-axis formatting
      x: {
        beginAtZero: true,
        ticks: {
          // Include a percentage sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return value * 100 + "%";
          },
        },
        title: {
          display: true,
          align: "center",
          text: xlabel,
          color: "rgb(0,0,0)",
          font: {
            family: "Open Sans",
            size: 12,
            style: "normal",
            weight: 600,
          },
          padding: 4,
        },
        grid: {
          borderColor: "#D9D9D9",
          color: "#D9D9D9",
          borderWidth: 1,
          lineWidth: 1,
          display: false,
        },
      },
      //y-axis formatting
      y: {
        //beginAtZero: true,
        max:700,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return "$" + value * 100;
          },
        },
        title: {
          display: true,
          align: "center",
          text: ylabel,
          color: "rgb(0,0,0)",
          font: {
            family: "Open Sans",
            size: 12,
            style: "normal",
            weight: 600,
          },
          padding: 4,
        },
        grid: {
          borderColor: "#D9D9D9",
          color: "#D9D9D9",
          borderWidth: 1,
          lineWidth: 1,
          display: true,
        },
      },
    },
    plugins: {
      autocolors: false,
      legend: {
        display: true,
        align: "end",
        labels: {
          boxWidth: 12,
          boxHeight: 12,
        },
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        // yPadding: 10,
        // xPadding: 30,
        // caretSize: 10,
        backgroundColor: "#007CB0",
        bodyFontColor: "#FFFFFF",
        bodyFont: {
          family: "Open Sans",
          size: 12,
          style: "normal",
          weight: 400,
        },
        cornerRadius: 0,
        bodySpacing: 4,
        padding: 8,
        callbacks: {
          label: (context: any) => {
            return [
              "Blended RPH : $" + context.raw.y,
              "USI Offshore % : " + context.raw.x + "%",
            ];
          },
        },
      },
    },
  };
  const rphOptions: any = {
    //maintainAspectRatio: false,
    scales: {
      //x-axis formatting
      x2: {
        ticks: {
          // Include a percentage sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return value + "%";
          },
        },
        border: {display : false},
        legend : {display : false},
        grid: {
          display: false,
        },
      },
      x: {
        display: false,
        beginAtZero: true,
        ticks: {
          display: false,
          // Include a percentage sign in the ticks
          // callback: function (value: any, index: any, ticks: any) {
          //   return value + "%";
          // },
        },
        // gridLines: {
        //   drawBorder: false,
        //   display: false,
        // },
        title: {
          display: false,
          align: "center",
          text: xlabel,
          color: "rgb(0,0,0)",
          font: {
            family: "Open Sans",
            size: 12,
            style: "normal",
            weight: 600,
          },
          padding: 4,
        },
        border: {display : false},
        legend : {display : false},
        grid: {
          borderColor: "#D9D9D9",
          color: "#D9D9D9",
          borderWidth: 1,
          lineWidth: 1,
          display: false,
        },
      },
      //y-axis formatting
      y: rphYAxisOptions,
    },
    plugins: {
      autocolors: false,
      legend: {
        display: false,
        align: "end",
        labels: {
          boxWidth: 12,
          boxHeight: 12,
        },
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        backgroundColor: "#007CB0",
        bodyFontColor: "#FFFFFF",
        bodyFont: {
          family: "Open Sans",
          size: 12,
          style: "normal",
          weight: 400,
        },
        cornerRadius: 0,
        bodySpacing: 4,
        padding: 8,
        callbacks: {
          label: (context: any) => {
            if(context.dataset.type === "line")
              return "Regression Value : " + parseInt(context.formattedValue).toFixed();
            else 
            {
              let accountRelation = context.raw.client_program ? "Account Relationship : " + (context.raw.client_program == "PLATINUM RELATIONSHIP"? 'Platinum'
              : (context.raw.client_program == "PREMIER RELATIONSHIP"? 'Premier':
              (context.raw.client_program == "ELITE RELATIONSHIP"? 'Elite':(context.raw.client_program == "STRATEGIC RELATIONSHIP"? 'Strategic':'-')))) : "";
              let organization = context.raw.new_addon_extension ? "Origination : " + (context.raw.new_addon_extension=="1"?'New':
              (context.raw.new_addon_extension=="2"?'Add-on':(context.raw.new_addon_extension=="3"?'Extension':'-'))) : "";
              // let organization = context.raw.new_addon_extension ? "Origination : " + (context.raw.new_addon_extension=="NEW"?'New':
              // (context.raw.new_addon_extension=="ADD-ON"?'Add-on':(context.raw.new_addon_extension=="EXTENSION"?'Extension':'-'))) : "";
              let rateCard = context.raw.msa_rate_card_type ? "Rate Card Used : " + (context.raw.msa_rate_card_type=="No"?'None':
              (context.raw.msa_rate_card_type=="Yes - Level Based"?'Level-based':(context.raw.msa_rate_card_type=="Yes - Role Based"?'Role-based':'-'))) : "";
              let secondaryMarketOffering = context.raw.secondary_market_offering_a ? "Secondary Market Offering : " + ((context.raw.secondary_market_offering_a!= undefined 
                && context.raw.secondary_market_offering_a!= null && context.raw.secondary_market_offering_a!='')?context.raw.secondary_market_offering_a:'-') : "";

              let totalBulgeorSpan = context.raw.bulge_soc ? context.raw.bulge_soc == "Span of Control" && context.raw.total_span_of_control ? 
                                    "Span of Control : " + context.raw.total_span_of_control : context.raw.bulge_soc == "Bulge Ratio" && context.raw.total_bulge_ratio ?
                                     "Bulge Ratio : " + context.raw.total_bulge_ratio : "" : "";
              let totalEngagementRevenuePerHour = context.raw.total_engagement_revenue_per_hour ? "RPH : " + context.raw.total_engagement_revenue_per_hour : "";

              let coordinateStrArr = [];
              if(accountRelation && accountRelation!='' && accountRelation!='Account Relationship : -') {coordinateStrArr.push(accountRelation);}
              if(organization) {coordinateStrArr.push(organization);}
              if(rateCard && rateCard!='' && rateCard!='Rate Card Used : -') {coordinateStrArr.push(rateCard);}
              if(secondaryMarketOffering) {coordinateStrArr.push(secondaryMarketOffering);}
              if(totalBulgeorSpan) {coordinateStrArr.push(totalBulgeorSpan);}
              if(totalEngagementRevenuePerHour) {coordinateStrArr.push(totalEngagementRevenuePerHour);}

              return coordinateStrArr;
            }
          },
        },
      },
      annotation: {
        annotations: {
          //Median line with shaded quartiles and labels
          upperQuartile: {
            type: "box",
            yMin: centerQuartileLineValue,
            yMax: upperQuartileValue,
            backgroundColor: "rgba(191, 187, 187, 0.25)",
            borderColor: "rgba(191, 187, 187, 0.25)",
          },
          lowerQuartile: {
            type: "box",
            yMin: lowerQuartileValue,
            yMax: centerQuartileLineValue,
            backgroundColor: "rgba(122, 120, 120, 0.25)",
            borderColor: "rgba(122, 120, 120, 0.25)",
          },
          medianLine: {
            type: "line",
            yMin: centerQuartileLineValue,
            yMax: centerQuartileLineValue,
            border: "1px solid #595959",
            label: {
              display: true,
              position: "end",
              backgroundColor: "transparent",
              color: "black",
              yAdjust: -6,
              font: {
                family: "Open Sans",
                size: 12,
                style: "normal",
                weight: 600,
                alignText: "top",
              },
              content: ["Median: $" + centerQuartileLineValue],
            },
          },
        },
      },
    },
  };
  const handleTimeFrameRange: MenuProps["onClick"] = (e) => {
    setTimeFrameValue(e.keyPath[0]);
  };
  const timeFrameDropdownMenu = (
    <Menu
      onClick={handleTimeFrameRange}
      items={[
        {
          label: "13 period rolling",
          key: "13 period rolling",
        },
        {
          label: "Last 4 months",
          key: "Last 4 months",
        },
        {
          label: "10 most recent",
          key: "10 most recent",
        },
      ]}
    />
  );
  const handleNsrRange: MenuProps["onClick"] = (e) => {
    setNsrRangeValue(e.keyPath[0]);
  };
  const nsrRangeDropdownMenu = (
    <Menu
      onClick={handleNsrRange}
      items={
        [
          {
            label: "All",
            key: "All",
          },
          ...Object.keys(nsrValueOptions)?.map((i: any) => {
            return {
              label: i,
              key: i,
            };
          }),
        ] || []
      }
    />
  );

  const handleHighlight: MenuProps["onClick"] = (e) => {
    setHighLightValue(e.keyPath[0]);
  };

  const highlightDropdownMenuOnJo = (
    <Menu
      onClick={handleHighlight}
      items={[
        {
          label: "Select",
          key: "Select",
        },
        {
          label: "Same Sector",
          key: "Same Sector",
        },
        {
          label: "Fixed Fee",
          key: "Fixed Fee",
        },
        {
          label: "Time & Material",
          key: "Time & Material",
        },
        {
          label: "New Engagements",
          key: "New Engagements",
        },
        {
          label: "Recent Wins",
          key: "Recent Wins",
        }
      ]}
    />
  );

  const highlightDropdownMenu = (
    <Menu
      onClick={handleHighlight}
      items={[
        {
          label: "Select",
          key: "Select",
        },
        {
          label: "Fixed Fee",
          key: "Fixed Fee",
        },
        {
          label: "Time & Material",
          key: "Time & Material",
        },
        {
          label: "New Engagements",
          key: "New Engagements",
        },
        {
          label: "Recent Wins",
          key: "Recent Wins",
        }
      ]}
    />
  );

  useEffect(() =>{
    if(highlightValue == "Same Sector" && searching == false){
      setHighLightValue("Select");
    }
  },[searching])

  return (
    <div className="chart-container">
      <div className="chart-dropdown">
        <div className="chart-dropdown-one">
          <div className="chart-dropdown-timeframe">
            <DropDown
              value={timeFrameValue || "13 period rolling"}
              menu={timeFrameDropdownMenu}
              placeholder="Timeframe"
            />
          </div>
          <div className="chart-dropdown-nsr">
            <DropDown
              value={nsrRangeValue || "All"}
              menu={nsrRangeDropdownMenu}
              placeholder="NSR Range"
            />
          </div>
          <div className="chart-dropdown-highlight">
            <DropDown
              value={highlightValue || "Select"}
              menu={searching == true ? highlightDropdownMenuOnJo : highlightDropdownMenu}
              placeholder="Highlight"
            />
          </div>
        </div>
      </div>
      <div className="chart-legends" data-testid="chart-legends">
        {Object.keys(nsrValueOptions).map((value: any, index: number) => {
          return (
            <span key={chartLegendColors[index]} className="chart-legend-box">
              <div
                className="color-box"
                style={{ backgroundColor: chartLegendColors[index] }}></div>
              {value}
            </span>
          );
        })}
      </div>
      {apiData.datasets.length ? (
        // <Scatter options={rphOptions} data={{ datasets: apiDataWithColors }} />
        <div>
          <Chart type="scatter" options={rphOptions} data={{labels:lineLabels, datasets: apiDataWithColors }} />
        </div>
      ) : (
        <Scatter options={noDataoptionsions} data={{ datasets: [] }} />
      )}
      <div className="dropdown-xaxis">
        <DropDownRphXaxis
          rphXaxisValue={rphXaxisValue}
          setRphXaxisValue={setRphXaxisValue}
        />
      </div>
    </div>
  );
}
