export const { API_CONFIG } = window;

export const deloitteId =
  ".36da45f1-dd2c-4d1f-af13-5abe46b99921-login.windows.net-36da45f1-dd2c-4d1f-af13-5abe46b99921";

export const accessTokenKey =
  ".36da45f1-dd2c-4d1f-af13-5abe46b99921-login.windows.net-accesstoken-061cf516-05ff-43ce-8d35-1a7249de2588-36da45f1-dd2c-4d1f-af13-5abe46b99921-061cf516-05ff-43ce-8d35-1a7249de2588/directory.accessasuser.all 061cf516-05ff-43ce-8d35-1a7249de2588/directory.read.all 061cf516-05ff-43ce-8d35-1a7249de2588/user.read 061cf516-05ff-43ce-8d35-1a7249de2588/user.read.all 061cf516-05ff-43ce-8d35-1a7249de2588/user.readbasic.all 061cf516-05ff-43ce-8d35-1a7249de2588/.default--";
export const oneMillion = 1000000;
export const oneThousand = 1000;
export const localStorageSuggestionsKey = "jupiterIdSuggestions";
