import { useState } from "react";
import "./CommonChart.css";
import "./EmptyChart.css";
import DropDown from "../input/DropDown";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { Empty, Menu, MenuProps } from "antd";
import { ChartProps } from "../../../shared/interfaces/chartInterface";
import DropDownRphXaxis from "./DropDownRphXaxis";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  annotationPlugin
);

export function EmptyChart({ xlabel, ylabel, chartType, apiData }: ChartProps) {
  const [timeFrameValue, setTimeFrameValue] = useState<any>("");
  const [nsrRangeValue, setnsrRangeValue] = useState<any>("");
  const [highLightValue, setHighLightValue] = useState<any>("");
  //Chart options and plugins
  const options: any = {
    //maintainAspectRatio: false,
    scales: {
      //x-axis formatting
      x: {
        beginAtZero: true,
        ticks: {
          // Include a percentage sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return chartType === "rphChart"
              ? value * 100 + "%"
              : "$" + value * 100 + "M";
          },
        },
        title: {
          display: true,
          align: "center",
          text: xlabel,
          color: "rgb(0,0,0)",
          font: {
            family: "Open Sans",
            size: 12,
            style: "normal",
            weight: 600,
          },
          padding: 4,
        },
        grid: {
          display: false,
        },
      },
      //y-axis formatting
      y: {
        beginAtZero: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return chartType === "rphChart"
              ? "$" + value * 100
              : value * 100 + "%";
          },
        },
        title: {
          display: true,
          align: "center",
          text: ylabel,
          color: "rgb(0,0,0)",
          font: {
            family: "Open Sans",
            size: 12,
            style: "normal",
            weight: 600,
          },
          padding: 4,
        },
        grid: {
          display: false,
        },
      },
    },
  };

  const timeFrameDropdownMenu = (
    <Menu onClick={() => {}} items={[]} data-testid="op-menu" />
  );
  const nsrRangeDropdownMenu = (
    <Menu onClick={() => {}} items={[]} data-testid="op-menu" />
  );

  const highlightDropdownMenu = (
    <Menu onClick={() => {}} items={[]} data-testid="op-menu" />
  );

  return (
    <div className="chart-container">
      <div className="chart-dropdown empty-chart-dropdown">
        <div className="empty-chart-dropdown-timeframe">
          <DropDown
            value={timeFrameValue || "13-period Rolling"}
            menu={timeFrameDropdownMenu}
            placeholder="Timeframe"
            isDisabled={true}
            data-testid="timeframe"
          />
        </div>
        <div className="chart-dropdown-nsr">
          <DropDown
            value={timeFrameValue || "All"}
            menu={nsrRangeDropdownMenu}
            placeholder="NSR Range"
            isDisabled={true}
            data-testid="nsrRange"
          />
        </div>
        <div className="chart-dropdown-highlight">
            <DropDown
              value={highLightValue || "Select"}
              menu={highlightDropdownMenu}
              placeholder="Highlight"
              isDisabled={true}
              data-testid="highlight"
            />
          </div>
      </div>

      <div className="chart-data">
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className="chart-no-data"
          data-testid="chart-no-data-test"
          description={<span data-testid="no-data">No Data</span>}
        />
        <Scatter options={options} data={apiData} data-testid="chart-test" />
      </div>
      {chartType === "rphChart" ? (
        <div className="dropdown-xaxis">
          <DropDownRphXaxis
            rphXaxisValue="% USI Offshore"
            setRphXaxisValue={() => {}}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
