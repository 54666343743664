import React, { useEffect, useState } from "react";
import { Avatar, Divider, Typography, Col, Row, Empty } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { iGuidanceBodyProps } from "../../../shared/interfaces/guidanceInterface";
import { guidanceEnums } from "../../../shared/enums/guidanceEnum";
import { getDate } from "../../../shared/utils/guidanceUtil";
import Label from "../input/Label";
import "./Guidance.css";
import {Parser} from "html-to-react";


const GuidanceBody = ({ guidanceData, selectedMO }: iGuidanceBodyProps) => {
  let updatedAtDate = new Date(guidanceData?.updated_at || new Date());
  let updated_at = getDate(updatedAtDate);
  const [code, setCode] = useState(guidanceData?.guidance.replaceAll("  ","&nbsp&nbsp"));

  useEffect(() => {
  }, []);

  return (
    <>
      <div className="mo-lead-details" data-testid="mo-lead-details">
        <Row>
          <Col>
            <Avatar
              icon={<UserOutlined />}
              className="mo-lead-image"
              data-testid="mo-lead-profile"
            />
          </Col>
          <Col>
            <Row>
              <p className="mo-lead-name" data-testid="mo-lead-name">
                {(selectedMO?.mo_lead_user?.first_name || "") +
                  " " +
                  (selectedMO?.mo_lead_user?.last_name || "")}
              </p>
              <p className="timestamp" data-testid="timestamp">
                {updated_at}
              </p>
            </Row>
            <Row>
              <p className="mo-lead-position" data-testid="mo-lead-position">
                {guidanceEnums.moLead}
              </p>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="guidance-body" data-testid="guidance-body">
        <div className="mo-lead-guidance">
          <ul>
            <>
              {guidanceData?.target_rph ? (
                <li key={"guidance-data-main"}>
                  <span className="mo-lead-guidance-heading">
                    {guidanceEnums.targetRph}
                  </span>
                  ${guidanceData?.target_rph}
                  <br />
                </li>
              ) : (
                ""
              )}

              {guidanceData?.commercial_guidance ? (
                <li key={"guidance-data-main"}>
                  <span className="mo-lead-guidance-heading">
                    {guidanceEnums.commercialGuidance}
                  </span>
                  {guidanceData?.commercial_guidance || ""}
                  <br />
                </li>
              ) : (
                ""
              )}
            </>
          </ul>
        </div>
        <div>

<div className="div-editor">
{Parser().parse(code)} 
</div>
        </div>
      </div>
    </>
  );
};

export default GuidanceBody;
