export enum homePage {
  offeringPortfolioDropdown = "Offering Portfolio",
  offeringDropdown = "Offering",
  marketOfferingDropdown = "Market Offering",
  heading = "Strategic Pricing Portal",
  offeringLead = "Offering Lead - ",
  offeringPortfolioLead = "Offering Portfolio Lead - ",
  marketOfferingLead = "Market Offering Lead - ",
  smartStartsLink = "https://resources.deloitte.com/sites/consulting/sandd/delivery_excellence/Pages/SmartStart.aspx",
}

export enum welcomeDialog {
  searchJupiterID = "Search for a Jupiter ID",
  skipToDashboard = "Skip to Dashboard",
  jupiterIdPlaceholder = "Enter a Jupiter ID in the format JO-#######",
  search = "Search",
  noDataFoundonSearchMsg = "We couldn’t find this Jupiter ID in our database – please check the format (JO-#######) or try again later.",
  issueOnBackEndOnSearchMsg = "Something went wrong! Please try again after some time.",
}
