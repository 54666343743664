import { Pagination, Form, Table, Button, MenuProps, Menu } from "antd";
import { FilterFilled } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import {
  fetchAPI,
  getErrorResponseStatus,
} from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import ErrorPage from "../../common/errorPage/ErrorPage";
import "./ViewFeedback.css";
import DropDown from "../../common/input/DropDown";
import ExportFeedbackButton from "./ExportFeedback";

const ViewFeedback = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [userTableData, setUserTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [roleItemsDetails, setRoleItemDetails] = useState<any>([]);
  const [filterScope, setFilterScope] = useState<string>("All");
  const [analyticLoaded, setAnalyticLoaded] = useState(false);

  useEffect(() => {
    getUserData();
    //getallroles();
    if(analyticLoaded == false) {
      sendAnalyticsData();
      sessionStorage.setItem("userManagementCall", "false");
      sessionStorage.setItem("dashboardCall", "false");
      sessionStorage.setItem("adminCall", "false");
    }
  }, []);

  const sendAnalyticsData = () =>{
    window.analyticsDataLayer = {
      pageInfo:{
        pageName:"Feedback",
        userID: sessionStorage.getItem('userName'),
        applicationName: "Strategic Pricing Portal",
        userType: sessionStorage.getItem("userType"),
      }
    };
    if(typeof window._satellite !== 'undefined'){
      //window._satellite.track("pageView");
    }
    setAnalyticLoaded(true);
  }

  const columns = [
    {
      title: "Rating",
      dataIndex: "rating",
    },
    {
      title: "Comments",
      dataIndex: "comments",
    },
    {
      title: "Email",
      dataIndex: "email_id",
    },
    {
      title: "Timestamp (UTC)",
      dataIndex: "updated_at",
      render: (_: any, record: any) => {
        let temp = new Date(record.updated_at);
        return `${temp.toUTCString().slice(0, -3)}`;
      },
    },
  ];
  const getallroles = async () => {
    setLoading(true);
    try {
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.feedback}`,
        {
          method: "GET",
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setRoleItemDetails(data?.responseData.data || []);
        } else {
          setRoleItemDetails([]);
        }
        setLoading(false);
      } else {
        console.log("Res Status", data?.responseStatus);
        setError("Error");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError("Error");
      setLoading(false);
    }

  };

  const getFilteredDetail = async (
    currentPage: number = 1,
    filterValue: string
  ) => {
    setLoading(true);
    try {
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.feedback}`,
        {
          method: "GET",
          queryParam: `page=${currentPage}&rating=${filterValue}`,
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setUserTableData(data?.responseData.data);
          if (currentPage == 1) {
            setTotalPage(data?.responseData?.message.total_records);
          }
        } else {
          setUserTableData([]);
        }
        setLoading(false);
      } else {
        console.log("Res Status", data?.responseStatus);
        setError(data.responseStatus);
        setLoading(false);
      }
    } catch (err) {
      console.log("Res Status", err);
      setError("error");
      setLoading(false);
    }

  };

  const getUserData = async (currentPage: number = 1) => {
    setLoading(true);
    try {
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.feedback}`,
        {
          method: "GET",
          queryParam: `page=${currentPage}`,
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setUserTableData(data?.responseData.data);
          if (currentPage == 1) {
            setTotalPage(data?.responseData?.message.total_records);
          }
        } else {
          setUserTableData([]);
        }
        setLoading(false);
      } else {
        console.log("Res Status", data?.responseStatus);
        setError(data.responseStatus);
        setLoading(false);
      }
    } catch (err) {
      setError("Error");
      setLoading(false);
    }

  };
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    if (filterScope === "All") {
      getUserData(pageNumber);
    } else {
      getFilteredDetail(pageNumber, filterScope);
    }
  };
  const handleSearchButton = () => {
    setCurrentPage(1);
    if (filterScope === "All") {
      getUserData();
    } else {
      getFilteredDetail(1, filterScope);
    }
  };
  const handleScopeChange: MenuProps["onClick"] = (e) => {
    setFilterScope(e.keyPath[0]);
    if (e.keyPath[0] === "All") {
      getUserData();
    } else {
      getFilteredDetail(1, e.keyPath[0]);
    }
  };
  const filterScopeDropdownMenu = (
    <Menu
      onClick={handleScopeChange}
      items={[
        {
          label: "All",
          key: "All",
        },
        {
          label: "1",
          key: "1",
        },
        {
          label: "2",
          key: "2",
        },
        {
          label: "3",
          key: "3",
        },
        {
          label: "4",
          key: "4",
        },
        {
          label: "5",
          key: "5",
        },
      ]}
    />
  );
  return (
    <Form onFinish={handleSearchButton}>
      {error === "" ? (
        <>
          <>
            <div className="user-main">
              <div className="label">
                <div className="filter-rating">
                  <div className="filter-span">
                    <FilterFilled
                      style={{ color: "#007CB0", fontSize: "19px" }}
                    />{" "}
                    <span className="filter-label">Ratings</span>
                  </div>
                  <DropDown
                    value={filterScope || "All"}
                    menu={filterScopeDropdownMenu}
                    placeholder=""
                    loading={loading}
                    className="scope-filter-dropdown"
                  />
                </div>
              </div>
              {/* Add export button here */}
              <ExportFeedbackButton></ExportFeedbackButton>
            </div>
            <>
              <Table
                columns={columns}
                dataSource={userTableData}
                pagination={false}
                loading={loading}
              />
              {userTableData.length > 0 ?
              <Pagination
              className="user-paginantion"
              onChange={handlePagination}
              defaultCurrent={currentPage}
              current={currentPage}
              total={totalPage}
            /> : ""}
              
            </>
          </>
        </>
      ) : (
        <ErrorPage responseStatus={getErrorResponseStatus(error)} />
      )}
    </Form>
  );
};

export default ViewFeedback;
