import { localStorageSuggestionsKey } from "../config/Constants";

export const addToSuggestions = (
  jupiterID: string,
  setSuggestions: React.Dispatch<any>
) => {
  let oldSuggestions: any = [];

  oldSuggestions = JSON.parse(
    localStorage.getItem(localStorageSuggestionsKey) || ""
  );
  let tempObj = new Set(oldSuggestions);
  tempObj.add(jupiterID);
  let uniqueSuggestions: any = Array.from(tempObj);
  let indexOfId = uniqueSuggestions.indexOf(jupiterID);
  uniqueSuggestions.splice(indexOfId, 1);
  uniqueSuggestions.splice(0, 0, jupiterID);
  if (uniqueSuggestions.length > 3) {
    uniqueSuggestions = uniqueSuggestions.slice(0, 3);
  }
  localStorage.setItem(
    localStorageSuggestionsKey,
    JSON.stringify(uniqueSuggestions)
  );
  setSuggestions(uniqueSuggestions);
};
