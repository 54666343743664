import React, { useEffect, useState } from "react";
import { Button } from "antd";
import "./WelcomeDialog.css";

export const SuggestionLabel = ({
  index,
  setJupiterID,
  setError,
  fetchOpportunityApiResponse,
  placeholder,
}: any): any => {
  const [isStateUpdated, setIsStateUpdated] = useState(false);

  const handleSuggestionClick = () => {
    setIsStateUpdated(true);
    setJupiterID(placeholder);
    setError("");
  };

  useEffect(() => {
    if (isStateUpdated) {
      fetchOpportunityApiResponse();
    }
    setIsStateUpdated(false);
  }, [isStateUpdated]);

  return (
    <Button
      key={index}
      onClick={() => handleSuggestionClick()}
      value={placeholder}
      className="labels"
      data-testid="suggestion-Label"
    >
      {placeholder}
    </Button>
  );
};
