import { fetchAPIEnums } from "../enums/fetchAPIEnums";

interface Ioptions {
  method: string;
  headers?: any;
  body?: string;
  queryParam?: string;
}

export const fetchCsvAPI = async (url: string, options: Ioptions) => {
  try {
    const response = await fetch(`${url}?${options?.queryParam || ""}`, {
      ...options,
    });
    const isJson = response.headers
      .get("content-type")
      ?.includes("application/json");
    const responseData1 = isJson ? await response.json() : null;

    const isCsv = response.headers.get("content-type")?.includes("text/csv");
    const responseData = isCsv ? await response.text() : responseData1;

    let errMsg;

    // const isCsv = response.headers
    //   .get("content-type")
    //   ?.includes("text/csv");
    // const responseData = isCsv ? await response.json() : null;
    // let errMsg;

    switch (response?.status) {
      case 403:
        errMsg = fetchAPIEnums.accessDenied;
        break;
      case 400:
        errMsg = fetchAPIEnums.badRequest;
        break;
      case 500:
        errMsg = fetchAPIEnums.internalServerError;
        break;
      default:
        break;
    }
    return {
      responseData,
      responseStatus: `${response?.status}`,
      errMsg,
    };
  } catch (err) {
    /*. TODO: Remove all console log*/
    console.error("Fetch API error :", err);
    return {
      errMsg: fetchAPIEnums.error,
      errorDetail: err,
      responseStatus: fetchAPIEnums.error,
    };
  }
};
export const getErrorResponseStatus = (errorStatus: string): any => {
  if (["403", "404", "500"].includes(errorStatus)) {
    return errorStatus;
  } else {
    return "error";
  }
};
