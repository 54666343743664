import { Button, Dropdown, Space } from "antd";
import React from "react";
import "./InputComponents.css";
import { DownOutlined } from "@ant-design/icons";
import Label from "./Label";
import { DropDownProps } from "../../../shared/interfaces/common";

const DropDown = ({
  value,
  menu,
  placeholder,
  isDisabled,
  isSubLabel = false,
  subLabel,
  subLabelName,
  loading = false,
  className,
}: DropDownProps) => {
  return (
    <>
      <div className="dropdown-label" data-testid="dropdown-title">
        <Label placeholder={placeholder} />
      </div>
      <Dropdown
        overlay={menu}
        className="dropdown"
        disabled={isDisabled ?? false}
        trigger={["click"]}
      >
        <Button loading={loading} data-testid="common-dropdown" className={className}>
          <Space>
            {value}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      {isSubLabel && (
        <div data-testid="dropdown-sublabel" className="dropdown-sublabel">
          <Label placeholder={subLabel ?? ""} />
          <span className="dropdown-subLabel">
            <Label
              style={{ color: "#0066cc" }}
              placeholder={subLabelName ?? ""}
            />
          </span>
        </div>
      )}
    </>
  );
};

export default DropDown;
