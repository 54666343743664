import { Button } from "antd";
import React from "react";
import { fetchCsvAPI } from "../../../shared/custom-hooks/fetchCsvAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import { FeedBackEnum } from "../../../shared/enums/feedBackEnums";

const border = "none";
const color = "#007CB0";
const fontColor = "white";
const disabled = false;
const className = "";

const ExportFeedbackResponse = async () => {
  const feedbackApiData = await fetchCsvAPI(
    //"http://127.0.0.1:5001/export_csv",
    `${process.env.REACT_APP_BASE_URL}${Endpoints.exportFeedback}`,
    {
      method: "GET",
      headers: {}
    }
  );

  const blob = new Blob([feedbackApiData.responseData], {
    type: "data:text/csv;charset=utf-8,",
  });
  const blobURL = window.URL.createObjectURL(blob);

  // Create new tag for download file
  const anchor = document.createElement("a");
  anchor.href = blobURL;
  anchor.dataset.downloadurl = ["text/csv", anchor.download, anchor.href].join(
    ":"
  );
  anchor.setAttribute("download", "feedback.csv");
  document.body.appendChild(anchor);
  anchor.click();
  anchor.remove();
};

const ExportFeedbackButton: React.FC = ({}) => (
  <Button
    data-testid="export-button-test"
    onClick={ExportFeedbackResponse}
    style={{
      backgroundColor: (!disabled && color) || "",
      border,
      color: disabled ? "#4C4E52" : fontColor,
    }}
    disabled={disabled}
    className={className}
    id="btnExport"
  >
    {FeedBackEnum.exportFeedbackButton}
  </Button>
);

export default ExportFeedbackButton;
