import { Button, Form, Menu, MenuProps, Pagination, Table } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import React, { useEffect, useState } from "react";
import { marketOfferingResponse } from "../../../shared/interfaces/HomePageInterfaces";
import CommonButton from "../../common/input/CommonButton";
import DropDown from "../../common/input/DropDown";
import TextField from "../../common/input/TextField";
import HighlightTable from "../../common/highlighter/HighlightTable";
import "./MarketOfferingManagement.css";

interface iMarketOfferingTableProps {
  columns: any;
  marketOfferingData: marketOfferingResponse[];
  fetchMarketOfferingApiResponse: Function;
  totalPage: number;
  loading: boolean;
  currentPage: number;
  setCurrentPage: Function;
  setAddMOModalOpen: Function;
  setSearchVal : Function;
}
export const MarketOfferingTable = ({
  columns,
  marketOfferingData,
  fetchMarketOfferingApiResponse,
  totalPage,
  loading,
  currentPage,
  setCurrentPage,
  setAddMOModalOpen,
  setSearchVal
}: iMarketOfferingTableProps) => {
  const [searchMOParam, setSearchMOParam] = useState("All");
  const [searchMOValue, setSearchMOValue] = useState("");
  const [enableBtn, setEnableBtn] = useState(false);
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    fetchMarketOfferingApiResponse(pageNumber);
  };
  const handleDropdownChange: MenuProps["onClick"] = (e) => {
    setSearchMOParam(e.keyPath[0]);
    if (e.keyPath[0] == "All") {
      setCurrentPage(1);
      fetchMarketOfferingApiResponse(1);
    }
    else{
      setSearchMOValue("");
    }
  };
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchMOValue(e.target.value);
    setSearchVal(e.target.value);
  };

  const handleSearchButton = () => {
    setCurrentPage(1);
    handleSearch();
  }

  useEffect(() =>{
    if(searchMOValue === "" && loading ===false)
    {
      handleSearch();
    }
  }, [searchMOValue])

  const handleSearch = () => {
    //TODO: remove trailing spaces. enable btn accordingly
    if(loading == false){
      setCurrentPage(1);
    let searchQP = "";
    if (searchMOParam === "MO Name")
      searchQP = `mo_name=${encodeURIComponent(
        searchMOValue.trim().replace(/\s\s+/g, " ")
      )}`;
    else if (searchMOParam === "MO Code")
      searchQP = `mo_code=${encodeURIComponent(
        searchMOValue.trim().replace(/\s\s+/g, " ")
      )}`;
    else if (searchMOParam === "Offering Code")
      searchQP = `offering_code=${encodeURIComponent(
        searchMOValue.trim().replace(/\s\s+/g, " ")
      )}`;
    else if (searchMOParam === "All")
      searchQP = `contains=${encodeURIComponent(
        searchMOValue.trim().replace(/\s\s+/g, " ")
      )}`;
      
    fetchMarketOfferingApiResponse(1, "");
    }
  };
  const mosearchFilterMenuItems = (
    <Menu
      onClick={handleDropdownChange}
      items={[
        {
          label: "All",
          key: "All",
        },
        {
          label: "Market Offering",
          key: "MO Name",
        },
        {
          label: "MO Code",
          key: "MO Code",
        },
        {
          label: "Offering Code",
          key: "Offering Code",
        },
      ]}
    />
  );
  useEffect(() => {
    if (!searchMOParam && !searchMOValue) setEnableBtn(false);
    else if (!searchMOParam && searchMOValue) setEnableBtn(false);
    else if (searchMOParam === "All") {
      setEnableBtn(true);
    } else {
      if (searchMOValue.trim().length) setEnableBtn(true);
      else setEnableBtn(false);
    }
  }, [searchMOParam, searchMOValue]);

  return (
    <div>
      <>
        <Form onFinish={handleSearch}>
          <div className="main">
            <div className="mo-admin-header">
              {/* <div className="mo-admin-header-filter">
                <DropDown
                  value={searchMOParam || "Search Using"}
                  menu={mosearchFilterMenuItems}
                  placeholder=""
                />
              </div> */}
              <div className="mo-admin-header-search">
                <TextField
                  onChange={handleTextFieldChange}
                  value={searchMOValue}
                  disabled={!searchMOParam}
                  name="search"
                  style={{ width: '30em' }}
                  placeholder="Search by MO Code, Market Offering, Offering, Market Offering Lead and Market Offering Delegate."
                  className="mo-admin-header-search-textfield textfield"
                />
                <CommonButton id="btnMarketOfferingSearch"
                  onClick={handleSearch}
                  value={"Search"}
                ></CommonButton>
              </div>
            </div>
            <CommonButton
              onClick={() => setAddMOModalOpen(true)}
              value="Add Market Offering"
            ></CommonButton>
          </div>
        </Form>
        <HighlightTable columns={columns} pagination={false}
                loading={loading} tableData={marketOfferingData} searchValue={searchMOValue} startPosition={11}/>

        {/* <Table id="tblData"
          columns={columns}
          dataSource={marketOfferingData}
          pagination={false}
          loading={loading}
        /> */}
        {marketOfferingData.length > 0 ? 
        <Pagination
          className="market-offering-pagination"
          onChange={handlePagination}
          defaultCurrent={currentPage}
          current={currentPage}
          total={totalPage}
        /> : ""}
        
      </>
    </div>
  );
};
