import { Card, Tabs } from "antd";
import "./Dashboard.css";
import ChartBase from "./ChartBase";
import { homePage } from "../../../shared/enums/homePageEnum";
import { iEngagementType } from "../../../shared/interfaces/HomePageInterfaces";
import { chartLabels } from "../../../shared/enums/chartEnums";
interface MarketOfferingInterface {
  marketOffering: string | undefined;
  marketOfferingId: number | undefined;
  revenue_range: number[] | [];
  engagement_type: iEngagementType;
  searching: boolean | false
}
function Dashboard({
  marketOffering,
  marketOfferingId,
  revenue_range,
  engagement_type,
  searching
}: MarketOfferingInterface) {
  const SmartStarts = (
    <a
      className="dashboard-getsmartstart"
      href={homePage.smartStartsLink}
      target="_blank"
    >
      Get My SmartStarts
    </a>
  );
  const items = [
		{
			label: "Market Pricing",
			key: "1",
			children: (
				<>
					<ChartBase
						marketOffering={marketOffering}
						marketOfferingId={marketOfferingId}
						revenue_range={revenue_range}
						revenue_range_m={revenue_range[2]}
						engagement_type={engagement_type}
            searching = {searching}
					/>
					<p className="custom-note"> {chartLabels.pricingSourceNote} <a href="mailto:uspricingandcommercialdealsupport@deloitte.com">US Pricing and Commercial Deal Support.</a> </p> 
				</>
			),
		},
	];
  return (
    <>
      <Card className="dashboard">
        <Tabs
          items={items}
          className="dashboard-tabs-main"
          tabBarExtraContent={SmartStarts}
          defaultActiveKey="1"
        />
      </Card>
    </>
  );
}

export default Dashboard;
