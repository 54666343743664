import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import "./ExpertDirectory.css";
import HelpIcon from "../../../assets/help_icon.png";
import CloseIcon from "../../../assets/closeButton.svg";
import backButton from "../../../assets/newBackButton.svg";
import CommonButton from "../input/CommonButton";
import { ExpertDirectoryEnum } from "../../../shared/enums/expertDirectoryEnum";
import {
  expertDirectoryContactInterface,
  expertDirectoryInterface,
} from "../../../shared/interfaces/expertDirectoryInterface";
import Loader from "../loader/Loader";
import {
  fetchAPI,
  getErrorResponseStatus,
} from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";

import ContactCard from "./ContactCard";

const ExpertDirectory = ({
  modalOpen,
  directoryContacts,
  setDirectoryContacts,
  directoryAreas,
  setDirectoryAreas,
  directoryTopics,
  setDirectoryTopics,
}: any) => {
  const [loading, setLoading] = useState(false);

  const [selectedItems, setSelectedItems] = useState({ area: 0, topic: 0 });
  const [isMinimizeClicked, setIsMinimizeClicked] = useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(modalOpen);
  // var directoryAreasData = [];
  // var directoryTopicsData = [];
  // var directoryContactsData = [];

  const fetchAreaApiResponse = async () => {
    setLoading(true);
    const getAreaApiData = await fetchAPI(
      `${process.env.REACT_APP_EXPERT_DIRECTORY_URL}${Endpoints.area}`,
      {
        method: "GET",
        headers: {}
      }
    );
    if (!getAreaApiData?.errMsg) {
      if (JSON.stringify(getAreaApiData?.responseData.data) !== "{}") {
        setDirectoryAreas(getAreaApiData?.responseData.data);
        setDirectoryTopics([]);
      }
    }
    setLoading(false);
  };

  const fetchTopicsApi = async (id: number) => {
    setLoading(true);
    const getTopicApiData = await fetchAPI(
      `${process.env.REACT_APP_EXPERT_DIRECTORY_URL}${Endpoints.topic}`,
      {
        method: "GET",
        queryParam: "area_id=" + id,
        headers: {}
      }
    );
    if (!getTopicApiData?.errMsg) {
      if (JSON.stringify(getTopicApiData?.responseData.data) !== "{}") {
        setDirectoryTopics(getTopicApiData?.responseData.data);
      }
    }
    setLoading(false);
  };

  const showAskExpert = () => {
    setIsModalOpen(true);
    if (!isMinimizeClicked) fetchAreaApiResponse();
  };

  // React.useEffect(() => {
  //   if (!isMinimizeClicked && isModalOpen) fetchAreaApiResponse();
  // }, [isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setDirectoryAreas([]);
    setDirectoryTopics([]);
    setDirectoryContacts(null);
    setSelectedItems({ area: 0, topic: 0 });
    setIsMinimizeClicked(false);
  };

  const handleMinimize = () => {
    setIsModalOpen(false);
    setIsMinimizeClicked(true);
  };
  const handleAreaClick = (id: number) => {
    setSelectedItems({ area: id, topic: 0 });
    fetchTopicsApi(id);
  };

  const handleTopicClick = (topic: expertDirectoryContactInterface) => {
    setSelectedItems({ ...selectedItems, topic: topic.id });
    setDirectoryContacts(topic);
  };

  const handleBack = () => {
    if (directoryContacts) {
      setDirectoryContacts(null);
    } else {
      setDirectoryTopics([]);
      setSelectedItems({ ...selectedItems, topic: 0 });
    }
  };

  useEffect(() => {
    setDirectoryAreas([]);
    setDirectoryTopics([]);
    setDirectoryContacts(null);
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [isModalOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <div>
        <img
          className="help-icon"
          src={HelpIcon}
          onClick={showAskExpert}
          width="50px"
          height="58px"
        />
      </div>
      <Modal
        bodyStyle={{
          padding: "0px",
          height: "32rem",
          margin: "0px",
        }}
        style={{
          top: "23%",
          float: "right",
          marginRight: "3%",
          paddingBottom: 0,
        }}
        width="30%"
        data-testid="directory-modal"
        open={isModalOpen}
        footer={null}
        closable={false}
      >
        <div className="expert-directory-modal">
          <Loader isLoading={loading} fullPage={false}>
            <div data-testid="directory-modal-open">
              <div className="directory-header">
                <div className="directory-header-1">
                  <div className="header-img">
                    <img src={HelpIcon} width="45px" height="45px" />
                  </div>
                  <div className="header-text">
                    <span className="directory-title">
                      {ExpertDirectoryEnum.title}{" "}
                    </span>
                    <p className="directory-subtitle">
                      {ExpertDirectoryEnum.subtitle}
                    </p>
                  </div>
                  {directoryTopics.length ? (
                    <div className="header-back-img">
                      <img
                        className="back-icon"
                        src={backButton}
                        onClick={handleBack}
                        width="30px"
                        height="30px"
                      />
                    </div>
                  ) : null}
                  {/* <div className="header-minimize-img">
                    <img
                      className="minimize-icon"
                      src={MinimizeIcon}
                      onClick={handleMinimize}
                      width="30px"
                      height="30px"
                    />
                  </div> */}
                  <div className="header-close-img">
                    <img
                      className="close-icon"
                      src={CloseIcon}
                      onClick={handleCancel}
                      width="37px"
                      height="37px"
                    />
                  </div>
                </div>
                {directoryContacts ? (
                  ""
                ) : (
                  <div className="directory-instructions">
                    <p className="directory-instructions-greeting">
                      {ExpertDirectoryEnum.greeting}
                    </p>

                    {directoryContacts ? (
                      <></>
                    ) : directoryTopics.length ? (
                      <p className="directory-instructions-question">
                        {ExpertDirectoryEnum.topicInstructions}
                      </p>
                    ) : (
                      <p className="directory-instructions-question">
                        {ExpertDirectoryEnum.areaInstructions}
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className="directory-contacts">
                {directoryContacts ? (
                  <div className="list-buttons">
                    <ContactCard directoryContacts={directoryContacts} />
                  </div>
                ) : directoryTopics.length ? (
                  <div
                    key={directoryTopics.length - 1}
                    className="topic-listings"
                  >
                    {directoryTopics.map(
                      (topic: expertDirectoryContactInterface) => {
                        return (
                          <div className="list-buttons">
                            <CommonButton
                              border="2px solid LIGHTBLUE"
                              color="#FFFFFF"
                              radius="20px"
                              fontColor="#000000"
                              value={topic.name}
                              height="100%"
                              onClick={() => handleTopicClick(topic)}
                              className={`expert-directory-options-btn ${
                                selectedItems?.topic === topic.id &&
                                "expert-directory-options-btn-selected"
                              }`}
                              //width="100%"
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <div
                    key={directoryAreas.length - 1}
                    className="area-listings"
                  >
                    {directoryAreas.map(
                      (area: { name: string; id: number }) => {
                        return (
                          <div className="list-buttons">
                            <CommonButton
                              border="2px solid LIGHTBLUE"
                              color="#FFFFFF"
                              radius="20px"
                              fontColor="#000000"
                              value={area.name}
                              onClick={() => handleAreaClick(area.id)}
                              className={`expert-directory-options-btn ${
                                selectedItems.area === area.id &&
                                "expert-directory-options-btn-selected"
                              }`}
                            />
                          </div>
                        );
                      }
                    )}
                  </div>
                )}
              </div>
            </div>
          </Loader>
        </div>
      </Modal>
    </>
  );
};

export default ExpertDirectory;
