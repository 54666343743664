import React, { useEffect, useState, useRef } from "react";
import { Pagination, Form, Table, Button, MenuProps, Menu } from "antd";

const HighlightTable = ({ columns, tableData, loading, pagination, searchValue, tblId = "tblData", startPosition = 0 }: any) => {

  useEffect(() => {
    if (searchValue != "") {
      let table = document.getElementById(tblId);
      var cols = table?.getElementsByClassName("ant-table-cell");
      if (cols != undefined && cols.length > startPosition +1) {
        let searchVal = searchValue.trim();
        let regexExp = new RegExp('(' + searchVal + ')', 'gi');
        let i = 0;
        for (i = startPosition; i < cols.length; i++) {
          let cellData = cols[i].innerHTML;
          cellData = cellData.replaceAll('&amp;', '&');
          if (cellData.toLowerCase().includes(searchVal.toLowerCase())) {
            if (!cellData.toLowerCase().includes('span role="img"')) {
              let split = cellData.split(regexExp);
              if (split.length > 0) {
                let replaceText = "";
                split.forEach(s => {
                  if (s.toLowerCase() == searchVal.toLowerCase()) {
                    replaceText += "<span style='Background-color:yellow'>" + s + "</span>";
                  }
                  else {
                    replaceText += s;
                  }
                });
                cols[i].innerHTML = replaceText;
              }
            }
          }
        }
      }
    }
  }, [tableData])

  return (
    <div>
      <Table id={tblId}
        columns={columns} dataSource={tableData}
        pagination={false} loading={loading}
      />
    </div>
  )
}

export default HighlightTable;