import { iDeleteUser } from "../../../shared/interfaces/userManagementInterface";
import { Modal, Typography } from "antd";
import CommonButton from "../../common/input/CommonButton";
import "./DeleteUser.css";
import { Endpoints } from "../../../shared/enums/endpoints";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import SuccessMessage from "../../common/input/SuccessMessage";
import ErrorMessage from "../../common/input/ErrorMessage";
import { deleteUserEnums } from "../../../shared/enums/userManagementEnum";
import { useEffect } from "react";

const { Title, Text } = Typography;

const DeleteUser = ({
  deleteModalOpen,
  setDeleteModalOpen,
  user,
  setUser,
  getUserData,
  currentPage,
  refreshTable,
  setFilterScope,
  setSearchValue,
  searchValue
}: iDeleteUser) => {
  const deleteUser = async () => {
    try {
      const options = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        queryParam: "user_id=" + user?.id + "" || "",
      };
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.user}`,
        options
      );
      if (!data?.errMsg) {
        SuccessMessage(
          `User ${
            user?.first_name + " " + user?.last_name
          } deleted successfully.`
        );
        setFilterScope("All");
        //setSearchValue("");
        refreshTable(1, searchValue);
      } else {
        if(data?.responseData.message.includes("offering_portfolio")){
          ErrorMessage("User you are trying to delete is a Offering Portfolio Lead. Please assign a new leader and then you can delete this user.");
        }
        else if(data?.responseData.message.includes("market_offering")){
          ErrorMessage("User you are trying to delete is a Market Offering Leader. Please assign a new leader and then you can delete this user.");
        }
        else if(data?.responseData.message.includes("offering"))
        {
          ErrorMessage("User you are trying to delete is a Offering Leader. Please assign a new leader and then you can delete this user.");
        }
        else{
          ErrorMessage("Something went wrong. Please try again later");
        }
      }
    } catch (err) {
      ErrorMessage("Something went wrong. Please try again later");
    }
  };
  const handleOk = () => {
    deleteUser();
    console.log("ok");
    setDeleteModalOpen(false);
  };

  const handleCancel = () => {
    setUser(null);
    setDeleteModalOpen(false);
  };
  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [deleteModalOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      setDeleteModalOpen(false);
    }
  };

  return (
    <>
      <Modal
        open={deleteModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        centered
      >
        <Title level={5}>
          {deleteUserEnums.heading}
          {user?.first_name + " " + user?.last_name + "?"}
        </Title>
        <div className="delete-user-btn-row">
          <div className="delete-user-cancel-btn">
            <CommonButton
              value="Cancel"
              onClick={handleCancel}
              color="#FFFFFF"
              fontColor="rgba(0, 0, 0, 0.85)"
              border="1px solid #D9D9D9"
            />
          </div>
          <CommonButton value="Delete" onClick={handleOk} />
        </div>
      </Modal>
    </>
  );
};

export default DeleteUser;
