import React, { useEffect, useState } from "react";
import { Divider, Typography, Empty } from "antd";
import {
  iGuidanceData,
  iGuidanceProps,
} from "../../../shared/interfaces/guidanceInterface";
import GuidanceFooter from "./GuidanceFooter";
import "./Guidance.css";
import AddGuidance from "../../leadership/addGuidance/AddGuidance";
import CommonButton from "../input/CommonButton";
import {
  fetchAPI,
  getErrorResponseStatus,
} from "../../../shared/custom-hooks/fetchAPI";
import ErrorPage from "../errorPage/ErrorPage";
import GuidanceBody from "./GuidanceBody";
import { guidanceEnums } from "../../../shared/enums/guidanceEnum";
import { Endpoints } from "../../../shared/enums/endpoints";
import Loader from "../loader/Loader";

const { Title } = Typography;
const url = `${process.env.REACT_APP_BASE_URL}${Endpoints.guidance}`;

const Guidance = ({ selectedMO, role, marketOffering, selectedOD, loggedUser, offeringPortfolio }: iGuidanceProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [guidanceData, setGuidanceData] = useState<iGuidanceData | null>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [updatedSession, setUpdatedSession] = useState(false);
  
  const fetchGuidance = async () => {
    setLoading(true);
    const options = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      queryParam: "mo_code=" + selectedMO?.mo_code + "",
    };
    const data: any = await fetchAPI(url, options);

    if (!data?.errMsg) {
      if (Object.keys(data.responseData.data || {}).length)
        setGuidanceData(data.responseData.data);
    } else {
      setError(data.responseStatus);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (marketOffering && selectedMO?.mo_code) {
      setError("");
      setGuidanceData(null);
      fetchGuidance();
    } else {
      setGuidanceData(null);
      setError("");
    }

	if(((role === "admin" && selectedMO?.mo_code) ||
	((role === "leader" || role === "admin") &&
		selectedMO?.mo_code && marketOffering &&
		(selectedMO?.mo_lead_user?.email_id === loggedUser ||
			selectedOD?.offering_lead_user?.email_id === loggedUser ||
			offeringPortfolio?.op_coo_user?.email_id === loggedUser ||
			selectedOD?.offering_delegate_user?.email_id == loggedUser ||
			selectedMO?.mo_delegate_user?.email_id == loggedUser)))){
				if(updatedSession == false){
					if(sessionStorage.getItem("searchJOFromWelcome") == "true"){
						sessionStorage.setItem("loadOfferingFromWelcome", "true");
						sessionStorage.setItem("searchJOFromWelcome", "false");
					}
					setUpdatedSession(true)
				}
			}
  }, [selectedMO, marketOffering]);

  return (
		<>
			<Loader isLoading={loading} fullPage={false}>
				<div className="guidance">
					{isModalOpen && (
						<AddGuidance
							isOpen={isModalOpen}
							setIsModalOpen={setIsModalOpen}
							guidanceData={guidanceData}
							mo_name={selectedMO?.mo_name || ""}
							mo_code={selectedMO?.mo_code || null}
							fetchGuidance={fetchGuidance}
						/>
					)}

					<div className="guidance-heading-main">
						<div className="guidance-heading">{guidanceEnums.heading}</div>
						{sessionStorage.getItem("loadOfferingFromWelcome") == "true" ? (
							<div
							className="guidance-button-main"
							data-testid="add-guidance-btn">
							<CommonButton id={`${guidanceData ? "btn_Update" : "btn_Add"}Guidance`}
								value={`${guidanceData ? "Update" : "Add"} Guidance`}
								onClick={() => setIsModalOpen(true)}
							/>
						</div>
						)
						: (selectedMO?.mo_code && marketOffering && ((role === "admin") ||
								(selectedMO?.mo_lead_user?.email_id === loggedUser ||
									selectedOD?.offering_lead_user?.email_id === loggedUser ||
									offeringPortfolio?.op_coo_user?.email_id === loggedUser ||
									selectedOD?.offering_delegate_user?.email_id == loggedUser ||
									selectedMO?.mo_delegate_user?.email_id == loggedUser))) && (
							<div
								className="guidance-button-main"
								data-testid="add-guidance-btn"
							>
								<CommonButton id={`${guidanceData ? "btn_Update" : "btn_Add"}Guidance`}
									value={`${guidanceData ? "Update" : "Add"} Guidance`}
									onClick={() => setIsModalOpen(true)}
								/>
							</div>
						)
						}
					</div>
					<Divider className="guidance-divider" />
					{error === "" ? (
						<>
							{marketOffering ? (
								<>
									<GuidanceBody
										selectedMO={selectedMO}
										guidanceData={guidanceData}
									/>

									<GuidanceFooter selectedMO={selectedMO} />
								</>
							) : (
								<Empty
									image={Empty.PRESENTED_IMAGE_SIMPLE}
									className="no-guidance"
									data-testid="no-guidance-image"
									description={<span>{guidanceEnums.noGuidance}</span>}
								/>
							)}
						</>
					) : (
						<div style={{ width: "100%" }}>
							<ErrorPage responseStatus={getErrorResponseStatus(error)} />
						</div>
					)}
				</div>
			</Loader>
		</>
	);
};

export default Guidance;
