import { iDeleteOfferingPortfolio } from "../../../shared/interfaces/userManagementInterface";
import { Modal, Typography } from "antd";
import CommonButton from "../../common/input/CommonButton";
import "./DeleteOfferingPortfolio.css";
import { Endpoints } from "../../../shared/enums/endpoints";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import SuccessMessage from "../../common/input/SuccessMessage";
import ErrorMessage from "../../common/input/ErrorMessage";
import { deleteOfferingPortfolioEnums } from "../../../shared/enums/userManagementEnum";

const { Title, Text } = Typography;

const DeleteOfferingPortfolio = ({
  deleteModalOpen,
  setDeleteModalOpen,
  offeringPortfolio,
  setOfferingPortfolio,
  getOfferingPortfolioData,
  currentPage,
}: iDeleteOfferingPortfolio) => {
  const deleteOfferingPortfolio = async () => {
    try {
      const options = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        queryParam: "op_code=" + offeringPortfolio?.op_code,
      };
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.offeringPortfolio}`,
        options
      );
      if (!data?.errMsg) {
        SuccessMessage(
          `Offering Portfolio ${offeringPortfolio?.op_name} deleted successfully.`
        );
        getOfferingPortfolioData(currentPage);
      }
       else {
        if(data.responseData.message.includes("is still referenced from table")){
          ErrorMessage("Cannot delete an Offering Portfolio with active Offerings under it.");
        }
        else{
          ErrorMessage("Something went wrong. Please try again later");
        }
      }
    } catch (err) {
      ErrorMessage("Something went wrong. Please try again later");
    }
  };
  const handleOk = () => {
    deleteOfferingPortfolio();
    setDeleteModalOpen(false);
  };

  const handleCancel = () => {
    setOfferingPortfolio(null);
    setDeleteModalOpen(false);
  };

  return (
    <>
      <Modal
        open={deleteModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        centered
      >
        <Title level={5}>
          {deleteOfferingPortfolioEnums.heading}
          {offeringPortfolio?.op_name + "?"}
        </Title>
        <div className="delete-OP-btn-row">
          <div className="delete-OP-cancel-btn">
            <CommonButton
              value="Cancel"
              onClick={handleCancel}
              color="#FFFFFF"
              fontColor="rgba(0, 0, 0, 0.85)"
              border="1px solid #D9D9D9"
            />
          </div>
          <CommonButton value="Delete" onClick={handleOk} />
        </div>
      </Modal>
    </>
  );
};

export default DeleteOfferingPortfolio;
