import { Avatar, Button, Form, Modal, Rate } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import CommonButton from "../input/CommonButton";
import { UserOutlined } from "@ant-design/icons";
import "./FeedBack.css";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import { iFeedback } from "../../../shared/interfaces/feedBackInterfaces";
import Loader from "../loader/Loader";
import SuccessMessage from "../input/SuccessMessage";
import ErrorMessage from "../input/ErrorMessage";
import {
  FeedBackEnum,
  ratingLabels,
} from "../../../shared/enums/feedBackEnums";

const FeedBack = ({
  setIsFeedBackOpen,
  isFeedBackOpen,
  userEmail,
}: iFeedback) => {
  const [form] = Form.useForm();
  const [ratingValue, setRatingValue] = useState<number>(3);
  const [comments, setComments] = useState<string>("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setRatingValue(3);
    setComments("");
  }, [isFeedBackOpen]);

  const handleOk = () => {
    submitFeedBack();
  };
  const submitFeedBack = async () => {
    setLoading(true);
    const postFeedBack = await fetchAPI(
      `${process.env.REACT_APP_BASE_URL}${Endpoints.feedback}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email_id: userEmail,
          rating: ratingValue,
          comments: comments,
        }),
      }
    );

    if (!postFeedBack?.errMsg) {
      setIsFeedBackOpen(false);
      SuccessMessage(FeedBackEnum.savedSucessfullMessage);
    } else {
      ErrorMessage(FeedBackEnum.errorInSavingMessage);
    }
    setLoading(false);
  };
  const ratingHandler = (rating: number) => {
    if (rating >= 1) setRatingValue(rating);
  };
  const handleCancel = () => {
    setIsFeedBackOpen(false);
  };

  const handleTextAreaChange = (e:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>) => {
    let specialChars = ["\"", "'", "\/", "@", "=",  "*",  "[",  "]", "(", ")"];
    let validText = true, x=0;
    for(x=0;x<specialChars.length;x++) {
      if(e.target.value.includes(specialChars[x])){
        validText = false;
        break;
      }
    }
    
    if(validText == true){
      setComments(e.target.value);
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [isFeedBackOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      handleCancel();
    }
  };

  return (
    <>
      <Modal
        title={FeedBackEnum.feedbackTitle}
        open={isFeedBackOpen}
        onOk={handleOk}
        // onCancel={handleCancel}
        closable={false}
        footer={null}
        centered
        data-testid="feedback-modal"
      >
        <Loader isLoading={loading} fullPage={false}>
          <div className="feedback_main">
            <div className="feedback_label">{FeedBackEnum.labelValue}</div>
            <Form data-testid="feedback-form" form={form} onFinish={handleOk}>
              <div className="feedback_rating">
                <Rate
                  onChange={ratingHandler}
                  value={ratingValue}
                  className="feedback_star"
                />
              </div>
              <div className="feedback_label">
                {ratingLabels[ratingValue - 1]}
              </div>
              <div className="feedback_comment_section">
                <Avatar icon={<UserOutlined />} className="feedback_avatar">
                  U
                </Avatar>
                <TextArea
                  data-testid="feedback-text"
                  onChange={handleTextAreaChange}
                  value={comments}
                  rows={4}
                  placeholder={FeedBackEnum.textAreaMessage}
                  maxLength={100}
                />
              </div>
            </Form>
            <div className="feedback_buttons">
              <div className="feedback_cancel">
                <CommonButton
                  disabled={false}
                  value={FeedBackEnum.cancelButton}
                  onClick={handleCancel}
                  color="#FFFFFF"
                  fontColor="#000000D9"
                  border="1px solid #D9D9D9"
                />
              </div>
              <div className="feedback_submit">
                <CommonButton
                  disabled={false}
                  value={FeedBackEnum.submitButton}
                  onClick={handleOk}
                />
              </div>
            </div>
          </div>
        </Loader>
      </Modal>
    </>
  );
};

export default FeedBack;
