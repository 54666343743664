import React from "react";
import { Input } from "antd";
import { TextFieldProps } from "../../../shared/interfaces/common";

const TextField = ({ onChange, ...rest }: TextFieldProps) => {
  return (
    <Input
      data-testid="textField-test"
      onChange={(e) => onChange(e)}
      {...rest}
    />
  );
};

export default TextField;
