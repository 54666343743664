import { oneMillion, oneThousand } from "../config/Constants";

const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const formatDate = (date: Date) => {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
};
interface Dictionary<T> {
  [Key: string]: T;
}
interface Dictionary2<T> {
  [Key: string]: T;
}

const getQueryParams: Dictionary<Function> = {
  "13 period rolling": () => {
    return `fin_year=${new Date().getFullYear().toString().slice(-2)}`;
  },

  "Last 4 months": () => {
    let to_date = new Date();
    to_date.setMonth(to_date.getMonth() - 4);
    return `from_date=${formatDate(to_date)}`;
  },

  "10 most recent": () => {
    return `latest_count=10`;
  },
};

export const queryParams: Dictionary2<string> = {
  "13 period rolling": getQueryParams["13 period rolling"](),

  "Last 4 months": getQueryParams["Last 4 months"](),

  "10 most recent": getQueryParams["10 most recent"](),
};
export const rhpXaxisParam: Dictionary<string> = {
  "% USI Offshore": "usi_offshore",
  "% US Core": "usi_core",
  "% USDC": "usdc",
};

const getAlteredNum = (val: number) => {
  if (val >= 1000000) {
    return val / 1000000 + "M";
  } else {
    return val / 1000 + "K";
  }
};
export const alterKMValues = (values: number[]) => {
  if (values[0] === 0) {
    return [
      "<$" + getAlteredNum(values[1]),
      "$" + getAlteredNum(values[1]) + " - $" + getAlteredNum(values[2]),
      "$" + getAlteredNum(values[2]) + "+",
    ];
  } else {
    return [
      "<$" + getAlteredNum(values[0]),
      "$" + getAlteredNum(values[0]) + " - $" + getAlteredNum(values[1]),
      "$" + getAlteredNum(values[1]) + " - $" + getAlteredNum(values[2]),
      "$" + getAlteredNum(values[2]) + "+",
    ];
  }
};

export function round(value: number, precision: number) {
  let multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export const getLabelWithKeys = (
  revenue_range: number[],
  alteredStrValues: string[]
) => {
  if (revenue_range[0] === 0) {
    return {
      [alteredStrValues[0]]: `&revenue_to=${revenue_range[1]}`,
      [alteredStrValues[1]]: `&revenue_from=${revenue_range[1]}&revenue_to=${revenue_range[2]}`,
      [alteredStrValues[2]]: `&revenue_from=${revenue_range[2]}`,
    };
  } else {
    return {
      [alteredStrValues[0]]: `&revenue_to=${revenue_range[0]}`,
      [alteredStrValues[1]]: `&revenue_from=${revenue_range[0]}&revenue_to=${revenue_range[1]}`,
      [alteredStrValues[2]]: `&revenue_from=${revenue_range[1]}&revenue_to=${revenue_range[2]}`,
      [alteredStrValues[3]]: `&revenue_from=${revenue_range[2]}`,
    };
  }
};
export const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
