import { Form, Menu, MenuProps, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import { editRoleEnums } from "../../../shared/enums/userManagementEnum";
import { iEditRole } from "../../../shared/interfaces/userManagementInterface";
import CommonButton from "../../common/input/CommonButton";
import DropDown from "../../common/input/DropDown";
import ErrorMessage from "../../common/input/ErrorMessage";
import SuccessMessage from "../../common/input/SuccessMessage";
import UnEditableDropDown from "../../common/input/UnEditableDropdown";
import "./EditRole.css";

const { Title, Text } = Typography;

const EditRole = ({
  editRoleModalOpen,
  setEditRoleModalOpen,
  userData,
  getUserData,
  currentPage,
  setSearchValue,
  refreshTable,
  setFilterScope,
  searchValue
}: iEditRole) => {
  const [valueChanged, setValueChanged] = useState(false);
  const [scope, setScope] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [roleMenuItems, setRoleMenuItems] = useState([]);
  const [roleItemsDetails, setRoleItemDetails] = useState<any>([]);

  const getallroles = async () => {
    setLoading(true);
    try {
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.roles}`,
        {
          method: "GET",
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setRoleMenuItems(
            data?.responseData.data.map((roleData: any) => {
              return {
                key: roleData.display_name,
                label: roleData.display_name,
              };
            })
          );
          setRoleItemDetails(data?.responseData.data || []);
        } else {
          setRoleMenuItems([]);
          setRoleItemDetails([]);
        }
      } else {
        console.log("Res Status", data?.responseStatus);
        setError("Error");
      }
    } catch (err) {
      console.log(err);
      setError("Error");
    }
    setLoading(false);
  };

  const updateUserDetails = async () => {
    setLoading(true);
    let tmp_role_id = roleItemsDetails.filter((item: any) => {
      if (`${item.display_name}` === scope) return item;
    });
    try {
      const options = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        queryParam: "user_id=" + userData?.id + "" || "",
        body: JSON.stringify({
          role_id: tmp_role_id[0].id,
        }),
      };
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.user}`,
        options
      );
      if (!data?.errMsg) {
        SuccessMessage("User "+ userData?.first_name + " " + userData?.last_name + " updated successfully.");
        refreshTable(1, searchValue);
      } else {
        ErrorMessage("Something went wrong. Please try again later");
      }
    } catch (err) {
      ErrorMessage("Something went wrong. Please try again later");
    }
    setLoading(false);
  };
  const [form] = Form.useForm();
  const handleOk = () => {
    updateUserDetails();
    setValueChanged(false);
    setError("");
    setEditRoleModalOpen(false);
  };
  const handleCancel = () => {
    setScope("");
    setError("");
    setValueChanged(false);
    setEditRoleModalOpen(false);
  };
  const handleScopeChange: MenuProps["onClick"] = (e) => {
    setScope(e.keyPath[0]);
    setValueChanged(true);
  };
  const scopeDropdownMenu = (
    <Menu onClick={handleScopeChange} items={roleMenuItems} />
  );
  useEffect(() => {
    getallroles();
  }, []);
  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [editRoleModalOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      setEditRoleModalOpen(false);
    }
  };
  return (
    <>
      <Modal
        open={editRoleModalOpen}
        closable={false}
        footer={null}
        width={"56.5rem"}
        centered
      >
        <Title level={5}>{editRoleEnums.heading}</Title>
        <Text>{editRoleEnums.subHeading}</Text>
        <Form form={form} layout="vertical" className="edit-role-modal-form">
          <div className="edit-role-modal-form-row">
            <span className="edit-role-row-item-1">
              <UnEditableDropDown
                placeholder={editRoleEnums.userNameLabel}
                value={userData?.first_name + " " + userData?.last_name || ""}
              />
            </span>
            <span className="edit-role-row-item-1">
              <UnEditableDropDown
                placeholder={editRoleEnums.emailLabel}
                value={userData?.email_id || ""}
              />
            </span>
            <span className="edit-role-row-item-2">
              <Form.Item>
                <DropDown
                  value={
                    scope ||
                    userData?.role.display_name ||
                    editRoleEnums.scopePlaceholder
                  }
                  menu={scopeDropdownMenu}
                  placeholder={editRoleEnums.scopeLabel}
                  loading={loading}
                />
              </Form.Item>
            </span>
          </div>
          <Form.Item className="edit-role-row-btn">
            <div className="edit-role-btn-1" data-testid="cancel-btn">
              <CommonButton
                value="Cancel"
                onClick={handleCancel}
                color="#FFFFFF"
                fontColor="rgba(0, 0, 0, 0.85)"
                border="1px solid #D9D9D9"
              />
            </div>
            <CommonButton
              value="Update"
              onClick={handleOk}
              disabled={!valueChanged}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EditRole;
