import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Dropdown, Menu, MenuProps, Space } from "antd";
import { irphXaxisDropDown } from "../../../shared/interfaces/chartInterface";
import "./DropDownRphXaxis.css";

const DropDownRphXaxis = ({
  rphXaxisValue,
  setRphXaxisValue,
}: irphXaxisDropDown) => {
  const rphDropDownHandler: MenuProps["onClick"] = (e) => {
    setRphXaxisValue(e.keyPath[0]);
  };
  const menu = (
    <Menu
      onClick={rphDropDownHandler}
      items={[
        {
          key: "% USI Offshore",
          label: "% USI Offshore",
        },
        {
          key: "% US Core",
          label: "% US Core",
        },
        {
          key: "% USDC",
          label: "% USDC",
        },
      ]}
    />
  );
  return (
    <Dropdown overlay={menu}>
      <a className="rphDropdown" onClick={(e) => e.preventDefault()}>
        <Space>
          {rphXaxisValue}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};

export default DropDownRphXaxis;
