import React from "react";
import "./SettingsMenu.css";
import { Button, Tooltip } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function SettingsMenu() {
	const navigate = useNavigate();
	const handleClick = () => navigate("/admin");

	return (
		<Tooltip title={"Go to Admin"} placement="left">
			<Button
				className="custom-button"
				icon={
					<SettingOutlined style={{ fontSize: "25px", color: "#ffffff" }} />
				}
				onClick={handleClick}
			></Button>
		</Tooltip>
	);
}
export default SettingsMenu;
