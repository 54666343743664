export const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_CLIENT_ID}`,
    authority: `${process.env.REACT_APP_URL_TENANT_ID}`, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_REDIRECTIONURL,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
// export const loginRequest = {
//   scopes: ["User.Read", "offline_access", "openid", "profile"],
// };

export const loginRequest = {
  scopes: [`${process.env.REACT_APP_CLIENT_ID}/.default`, "offline_access", "email"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  profilePic: "https://graph.microsoft.com/v1.0/me/photo/$value",
};
