import { Button, Card, Menu } from "antd";
import React, { useEffect } from "react";
import DropDown from "../common/input/DropDown";
import "./DropdownEditableComponent.css";
import type { MenuProps } from "antd";
import { homePage } from "../../shared/enums/homePageEnum";
import { marketOfferingResponse } from "../../shared/interfaces/HomePageInterfaces";
import editLogo from "../../assets/editLogo.svg";
import UnEditableDropDown from "../common/input/UnEditableDropdown";

const DropdownEditableComponent = ({
  offeringPortfolio,
  offering,
  offeringCode,
  searchMercuryApiData,
  marketOffering,
  offeringDetails,
  setOfferingDetails,
  setSelectedMOData,
  setOffering,
  setOfferingPortfolio,
  setMarketOffering,
  setMarketOfferingDetails,
  marketOfferingDetails,
  searching,
  setSearching,
  mof_code,
  offeringPortfolioDetails
}: any) => {
  const [marketOfferingMenuItems, setMarketOfferingMenuItems] = React.useState<
    marketOfferingResponse[]
  >([]);

  const [MarketOfferingLoading, setMarketOfferingLoading] =
    React.useState(false);

  const updateSelectedMOData = (item: marketOfferingResponse[]) => {
    let selectedMo = searchMercuryApiData.filter(
      (dataset: any) => dataset?.mof_code == item[0]?.mo_code
    )[0];
    let tempOfferingDetails = [{}];
    tempOfferingDetails[0] = selectedMo?.offering;
    setOfferingDetails(tempOfferingDetails);
    setOfferingPortfolio(selectedMo?.offering_portfolio.op_name);
    return selectedMo;
  };
  
  const handleMarketOffering: MenuProps["onClick"] = (e) => {
    sessionStorage.setItem("loadOfferingFromWelcome", "false");
    sessionStorage.setItem("loadFromWelcome", "true");
    const selectedItem: marketOfferingResponse[] =
      marketOfferingMenuItems.filter((obj) => {
        return obj?.key.toString() === e.key;
      });
    setSelectedMOData(updateSelectedMOData(selectedItem));
    setMarketOffering(selectedItem[0]?.label);
    setMarketOfferingDetails(selectedItem);
    setOffering(selectedItem[0]?.offering.offering_name);
  };

  React.useEffect(() => {
    if (offeringCode) fetchMarketOfferingApiResponse();
  }, [offeringCode]);

  const marketOfferingDropdownMenu = (
    <Menu onClick={handleMarketOffering} items={marketOfferingMenuItems} />
  );

  const fetchMarketOfferingApiResponse = async () => {
    setMarketOfferingLoading(true);

    let extractedMOData: any[] = [];

    searchMercuryApiData.forEach((dataset: { mo: any }) =>
      extractedMOData.push(dataset?.mo)
    );
    setMarketOfferingMenuItems(extractedMOData);
    setMarketOfferingLoading(false);
  };

  return (
    <div className="site-card" data-testid="uneditable-dropdown">
      <Card
        className="select-dropdowns-card"
        bordered={false}
        data-testid="dropdowns-uneditable"
      >
        <div className="dropdown-div" data-testid="uneditable-dropdown-div">
          <UnEditableDropDown
            value={offeringPortfolio || "No Value Selected"}
            placeholder={homePage.offeringPortfolioDropdown}
            isSubLabel={true}
            subLabel={offeringPortfolioDetails?.op_lead != null ? homePage.offeringPortfolioLead : ""}
            subLabelName = {
              offeringPortfolioDetails?.op_lead != null ? offeringPortfolioDetails?.op_lead?.first_name + " " 
              + offeringPortfolioDetails?.op_lead?.last_name : ""
            }
          />
        </div>
        <div className="dropdown-div" data-testid="uneditable-dropdown-div">
          <UnEditableDropDown
            value={offering || "No Value Selected"}
            placeholder={homePage.offeringDropdown}
            isSubLabel={true}
            subLabel={offering ? homePage.offeringLead : ""}
            subLabelName={
              offering
                ? offeringDetails?.offering_lead_user?.first_name +
                  " " +
                  offeringDetails?.offering_lead_user?.last_name
                : ""
            }
          />
        </div>
        <span className="dropdown-div">
          <DropDown
            value={marketOffering || "Select Market Offering "}
            placeholder={homePage.marketOfferingDropdown}
            menu={marketOfferingDropdownMenu}
            loading={MarketOfferingLoading}
            isSubLabel={true}
            subLabel={marketOffering ? homePage.marketOfferingLead : ""}
            subLabelName={
              marketOffering
                ? marketOfferingDetails?.mo_lead_user?.first_name +
                  " " +
                  marketOfferingDetails?.mo_lead_user?.last_name
                : ""
            }
          />
        </span>
      </Card>
      {searching && (
        <div className="edit-icon">
          <Button
            className="edit-button"
            data-testid="edit-button"
            onClick={() => setSearching && setSearching(false)}
          >
            <img src={editLogo} alt={"edit logo"} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default DropdownEditableComponent;
