export enum FeedBackEnum {
  feedbackTitle = "Rate this tool",
  textAreaMessage = "Additional comments",
  savedSucessfullMessage = "Feedback saved successfully.",
  errorInSavingMessage = "Something went wrong, please try again later",
  labelValue = "Please provide your feedback for this portal.",
  submitButton = "Submit",
  cancelButton = "Cancel",
  toolTip = "Rate this Tool",
  exportFeedbackButton = "Download Feedback",
}
export const ratingLabels = [
  "Terrible - Could find No use for this information.",
  "Disappointing - Struggled to get what I wanted out of the tool.",
  "Functionality is OK - I May or May Not Come Back.",
  "Good tool - It's improving my Pricing Experience.",
  "Excellent tool - It's meaningfully improving my Pricing Experience.",
];
