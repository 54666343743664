import { Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState, useCallback } from "react";
import { Component } from 'react';
import { iAddGuidanceProps } from "../../../shared/interfaces/guidanceInterface";
import CommonButton from "../../common/input/CommonButton";
import TextField from "../../common/input/TextField";
import { Form } from "antd";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import "./AddGuidance.css";
import { addGuidanceEnums } from "../../../shared/enums/guidanceEnum";
import SuccessMessage from "../../common/input/SuccessMessage";
import { Endpoints } from "../../../shared/enums/endpoints";
import ErrorMessage from "../../common/input/ErrorMessage";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const url = `${process.env.REACT_APP_BASE_URL}${Endpoints.guidance}`;

const AddGuidance = ({
  isOpen,
  setIsModalOpen,
  guidanceData,
  mo_code,
  mo_name,
  fetchGuidance,
}: iAddGuidanceProps) => {
  

  const title = (
    <span className="modal-title" data-testid="title">
      {" "}
      {`${
        guidanceData
          ? addGuidanceEnums.updateGuidanceHeading
          : addGuidanceEnums.addGuidanceHeading
      } ${mo_name ? mo_name : ""}`}
    </span>
  );

  const initial_val = {
    target_rph: guidanceData ? guidanceData.target_rph : "",
    commercial_guidance: guidanceData ? guidanceData.commercial_guidance : "",
    guidance: guidanceData ? guidanceData.guidance : "",
  };

  const [form] = Form.useForm();
  const [guidance, setGuidance] = useState(initial_val);
  const [valueChanged, setValueChanged] = useState(false);
  const [initialGuidanceVal, setInitialGuidanceVal] = useState("");
  const [code, setCode] = useState("");

  const  modules  = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        ["blockquote", "code-block"],
        [{ list:  "ordered" }, { list:  "bullet" }],
        ["clean"],
    ],
};

const handleProcedureContentChange = (content: any) => {
  setValueChanged(true);
  setCode(content);
};

  const updateGuidance = async () => {
    guidance.commercial_guidance = guidance?.commercial_guidance?.trim();
    const tmpObj = guidance;
    // if(guidance.target_rph==="") tmpObj.target_rph=0;
    const options = {
      method: guidanceData ? "PUT" : "POST",
      headers: { "Content-Type": "application/json" },
      queryParam: "mo_code=" + mo_code + "",
      body: JSON.stringify(tmpObj),
    };
    const data: any = await fetchAPI(url, options);
    if (!data?.errMsg) {
      const popupmsg = guidanceData
        ? addGuidanceEnums.updateMessage
        : addGuidanceEnums.addMessage;
      SuccessMessage(popupmsg);
      fetchGuidance();
    } else {
      ErrorMessage("Something went wrong. Please try again later");
    }
  };
  
  const handleCancel = () => {
    setGuidance(initial_val);
    setIsModalOpen(false);
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setGuidance({
      ...guidance,
      [e.target.name]: e.target.value,
    });
    setValueChanged(true);
  };

  const handleChangeRph = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setGuidance({
        ...guidance,
        [e.target.name]: e.target.value,
      });
      setValueChanged(true);
    } else {
      let x = guidance.target_rph;
      e.target.value = x.toString();
    }
  };

  const handleSubmit = () => {
    //let content = editorState.getCurrentContent();
    //let rawData = convertToRaw(content);
    let convertedToSpaces = code.replaceAll("&nbsp;", " ");
    guidance.guidance = convertedToSpaces;
    updateGuidance();
    setIsModalOpen(false);
  };

  const setInitialVal = () =>{
    let guidanceVal = guidance.guidance;
    let res = guidanceVal.replaceAll("  ", "&nbsp&nbsp");
    setCode(res);
    setTimeout(()=>{setValueChanged(false);},100);
    
    // if(guidanceVal.includes("inlineStyleRanges")){
    //   setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(guidanceVal))));
    // }
    // else if(guidanceVal != null){
    //   let initTextcontent = ContentState.createFromText(guidanceVal);
    //   setEditorState(EditorState.createWithContent(initTextcontent));
    // }
  };

  useEffect(() => {
    setInitialVal();
  }, []);

  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [isOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      handleCancel();
    }
  };

  const editorOnChange = () => {
    setValueChanged(true);
  }

  return (
    <div data-testid="guidance-form">
      <Modal
        title={title}
        closable={false}
        open={isOpen}
        centered
        // onCancel={handleCancel}
        footer={null}
        className="add-guidance-modal"
        width={"56.625rem"}
      >
        <Form
          form={form}
          layout="vertical"
          className="add-guidance-modal-form"
          data-testid={"modal-form"} >
          <div className="form-row-1">
            <Form.Item
              label={addGuidanceEnums.taregtRphLabel}
              className="form-item-1"
            >
              <TextField
                onChange={handleChangeRph}
                value={guidance.target_rph}
                name="target_rph"
                maxLength={8}
                placeholder={`${
                  guidance.target_rph
                    ? `$ ${guidance.target_rph}`
                    : addGuidanceEnums.inputPlaceholder
                }`}
                prefix={
                  <span
                  // style={{color:"black"}}
                  >
                    $
                  </span>
                }
                size="large"
                className={"textfield"}
                status={guidance.target_rph === "" ? "error" : ""}
              />
            </Form.Item>
            <Form.Item
              label={addGuidanceEnums.commercialGuidance}
              className="form-item-2"
            >
              <TextField
                onChange={handleChange}
                value={guidance.commercial_guidance}
                type="text"
                name="commercial_guidance"
                placeholder={`${
                  guidance.commercial_guidance
                    ? guidance.commercial_guidance
                    : addGuidanceEnums.inputPlaceholder
                }`}
                size="large"
                className={"textfield"}
                maxLength={100}
                prefix={<></>}
              />
            </Form.Item>
          </div>
          {/* <Form.Item label={addGuidanceEnums.guidance} className="form-item-3">
          <Editor editorState={editorState} onEditorStateChange={setEditorState}
          wrapperClassName="wrapper-class" editorClassName="editor-class" toolbarClassName="toolbar-class"
          toolbar={{ options: ['inline', 'list', 'history'],
          list: { options: ['unordered', 'ordered' ]}, 
          inline:{options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace']}
 ,colorPicker: { visible: true }
          }}
          placeholder= "     Enter here" onChange={editorOnChange} />
          </Form.Item> */}

<Form.Item label={addGuidanceEnums.guidance} className="form-item-3">
<>
      <ReactQuill
        theme="snow"
        modules={modules}
        value={code}
        onChange={handleProcedureContentChange}
        placeholder="   Enter here"
      />
    </>
          </Form.Item>
          <Form.Item className="form-item-4">
            <div className="form-item-4-btn" data-testid="cancel-btn">
              <CommonButton
                value="Cancel"
                onClick={handleCancel}
                color="#FFFFFF"
                fontColor="rgba(0, 0, 0, 0.85)"
                border="1px solid #D9D9D9"
              />
            </div>
            <CommonButton id={`${guidanceData ? "Update" : "Add"}Guidance`}
              value={`${guidanceData ? "Update" : "Add"} Guidance`}
              onClick={handleSubmit}
              disabled={guidance.target_rph === "" || !valueChanged}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AddGuidance;
