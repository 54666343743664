import React from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
// import { SignOutButton } from "./SignOut";

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
function handleLogin(instance: any) {
  // instance.loginRedirect(loginRequest).catch((e: string) => {
  //   console.error(e);
  // });
  instance
    .handleRedirectPromise()
    .then(() => {
      // Check if user signed in
      const account = instance.getActiveAccount();
      if (!account) {
        // redirect anonymous user to login page
        instance.loginRedirect(loginRequest);
      }
    })
    .catch((err: any) => {
      console.log(err);
    });
}
export const PageLayout = (props: any) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      {/* {isAuthenticated ? <SignOutButton /> : handleLogin(instance)} */}
      {isAuthenticated ? "" : handleLogin(instance)}
      {isAuthenticated && props.children}
    </>
  );
};
