import { iContactCard } from "../../../shared/interfaces/expertDirectoryInterface";
import "./ExpertDirectory.css";
const ContactCard = ({ directoryContacts }: iContactCard) => {
  return (
    <div className="contacts-main">
      <div className="contacts-name">
        {directoryContacts.alias.includes("@deloitte.com") ? (
          <a target="_blank" href={"mailto:" + directoryContacts.alias}>
            {directoryContacts.contact_name}
          </a>
        ) : (
          <a target="_blank" href={directoryContacts.alias}>
            {directoryContacts.contact_name}
          </a>
        )}
      </div>
    </div>
  );
};

export default ContactCard;
