import { Typography } from "antd";
import React, { useEffect, useState } from "react";
import { fetchAPI, getErrorResponseStatus } from "../../../shared/custom-hooks/fetchAPI";
import ErrorMessage from "../../common/input/ErrorMessage";
import SuccessMessage from "../../common/input/SuccessMessage";
import { Endpoints } from "../../../shared/enums/endpoints";
import Loader from "../../common/loader/Loader";
import "./FileUpload.css";
import { Pagination, Form, Table, Button, MenuProps, Menu } from "antd";
import ErrorPage from "../../common/errorPage/ErrorPage";
import { CheckCircleOutlined, CloseCircleOutlined, FieldTimeOutlined } from "@ant-design/icons";


const { Title, Text } = Typography;
function FileUpload() {
  const [file, setFile] = React.useState<any>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [fileTableData, setFileTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [error, setError] = useState("");
  const [analyticLoaded, setAnalyticLoaded] = useState(false);


  function handleUpload(event: any) {
    if (validateFile(event.target.files[0])) {
      setFile(event.target.files[0]);
    } else {
      if (event.target.files[0] !== undefined) {
        ErrorMessage(`${event.target.files[0]?.name} is not a valid file`);
      }
      event.target.value = null;
      setFile("");
    }
  }

  const getSignedURL = async (fileName: string) => {
    setLoading(true);
    try {
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_FILE_UPLOAD_URL}`,
        {
          method: "GET",
          queryParam: `filename=${fileName}`,
          headers: {}
        }
      );

      if (!data?.errMsg) {
        uploadFileToAws(data.responseData["Pre-Signed Url"]);
      } else {
        console.log("Res Status", data?.responseStatus);
        ErrorMessage("Error while uploading file");
      }
    } catch (err) {
      console.log(err);
      ErrorMessage("Error while uploading file");
    }
    setLoading(false);
  };
  const uploadFileToAws = async (url: string) => {
    setLoading(true);
    try {
      const data: any = await fetch(`${url}`, {
        method: "PUT",
        body: file,
        headers: {}
      });

      if (!data?.errMsg) {
        SuccessMessage("File uploaded sucessfully.");
        setFile("");
        getUploaded();
      } else {
        console.log("Res Status", data?.responseStatus);
        ErrorMessage("Error while uploading file.");
      }
    } catch (err) {
      console.log(err);
      ErrorMessage("Error while uploading file.");
    }
    setLoading(false);
  };

  

  useEffect(() => {
    getUploaded();
    if(analyticLoaded == false){
      window.analyticsDataLayer = {
        pageInfo:{
          pageName:"File upload",
          userID: sessionStorage.getItem('userName'),
          applicationName: "Strategic Pricing Portal",
          userType: sessionStorage.getItem("userType"),
        }
      };
      if(typeof window._satellite !== 'undefined'){
        //window._satellite.track("pageView");
      }
      setAnalyticLoaded(true);
      sessionStorage.setItem("userManagementCall", "false");
      sessionStorage.setItem("dashboardCall", "false");
      sessionStorage.setItem("adminCall", "false");
    }
  }, []);

  const columns = [
    {
      title: "Status",
      dataIndex: "is_processed",
      render: (_:any, record: any) =>(
        <div className="admin-action-area">
          {record.is_processed === false && record.processed_message.includes("Processing") ? 
          <FieldTimeOutlined style={{ fontSize: "18px", color: "yellow" }} /> : record.is_processed === true ?
          <CheckCircleOutlined style={{ fontSize: "18px", color: "green" }} /> 
          : <CloseCircleOutlined style={{ fontSize: "18px", color: "red" }}/>}
      </div>
      ),
    },
    {
      title: "File name",
      dataIndex: "updated_file_name",
    },
    {
      title: "Message",
      dataIndex: "processed_message",
    },
    {
      title: "Uploaded by",
      dataIndex: "created_by",
    },
    {
      title: "Timestamp (UTC)",
      dataIndex: "created_at",
      render: (_: any, record: any) => {
        let temp = new Date(record.updated_at);
        return `${temp.toUTCString().slice(0, -3)}`;
      },
    }
  ];

  const getUploaded = async (currentPage: number = 1) =>{
    setLoading(true);
    try {
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.upload}`,
        {
          method: "GET",
          queryParam: `page=${currentPage}`,
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setFileTableData(data?.responseData.data || []);
          if (currentPage == 1) {
            setTotalPage(data?.responseData?.message.total_records);
          }
        } else {
          setFileTableData([]);
        }
        setLoading(false);
      } else {
        console.log("Res Status", data?.responseStatus);
        setError("Error");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError("Error");
      setLoading(false);
    }
  }

  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    getUploaded(pageNumber);
  };

  const validateFileType = (fileType: string) => fileType === "text/csv";
  const validateFileName = (fileName: string) =>
    fileName === "pme_extract.csv" || fileName === "opportunity_extract.csv";

  const validateFile = (fileToValidate: any) =>
    validateFileType(fileToValidate?.type) &&
    validateFileName(fileToValidate?.name);

  const reload = () =>{
    getUploaded(1);
  }

  const handleBtn = () => {
    if (validateFile(file)) {
      getSignedURL(file?.name);
    } else {
      ErrorMessage("Please upload a file");
    }
  };

  return (
    <div>
      <div className="upload-box">
      <Loader isLoading={loading} fullPage={false}>
        <Title level={5}>Upload your file</Title>
        <div className="file-input">
          <input type="file" onChange={handleUpload} accept=".csv" />
          <div className="box">
            <span className="button">Choose</span>
            <span className="label">{file?.name}</span>
          </div>
        </div>
        <div className="file-rule">
          <ul>
            <li>
              File Name should be either <b>opportunity_extract</b> or
              <b>&nbsp;pme_extract</b>
            </li>
            <li>
              File Type should be <b>CSV</b> file
            </li>
            <li>Supports: &#60;filename &#62;.csv</li>
          </ul>
        </div>
        <button className="upload-btn" id="btnUpload" onClick={handleBtn}>
          Upload
        </button>
      </Loader>
      </div>
      <Form className="tbl-filelogs">
      {error === "" ? (
        <>
        <div className="div-button-reload">
        <button className="btn-reload" id="btnRefresh" onClick={reload}> Refresh </button>
        </div>
              <Table
                columns={columns}
                dataSource={fileTableData}
                pagination={false}
                loading={loading}
              />
              <Pagination
                className="user-paginantion"
                onChange={handlePagination}
                defaultCurrent={currentPage}
                current={currentPage}
                total={totalPage}
              />
            </>
      ) : (
        <ErrorPage responseStatus={getErrorResponseStatus(error)} />
      )}
    </Form>
    </div>
  );
}

export default function App() {
  return <FileUpload />;
}
