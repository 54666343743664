import React from "react";
import "./InputComponents.css";
import Label from "./Label";
import { UnEditableDropDownProps } from "../../../shared/interfaces/common";

const UnEditableDropDown = ({
  value,
  placeholder,
  isSubLabel = false,
  subLabel,
  subLabelName,
}: UnEditableDropDownProps) => {
  return (
    <>
      <div className="dropdown-label" data-testid="dropdown-title">
        <Label placeholder={placeholder} />
      </div>
      <Label
        placeholder={value}
        style={{ fontSize: "1rem", fontWeight: 600 }}
      />
      {isSubLabel && (
        <div
          className="unEditable-dropdown-subLabel"
          data-testid="unEditable-dropdown-sublabel"
        >
          <Label placeholder={subLabel ?? ""} />
          <span className="unEditable-dropdown-subLabelName">
            <Label
              style={{ color: "#0066cc" }}
              placeholder={subLabelName ?? ""}
            />
          </span>
        </div>
      )}
    </>
  );
};

export default UnEditableDropDown;
