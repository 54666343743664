export enum addUserEnums {
  heading = "Add New User",
  subHeading = "This will help you to add a new user and assign the role.",
  firstNameLabel = "First Name *",
  firstNamePlaceholder = "Enter first name",
  lastNameLabel = "Last Name *",
  lastNamePlaceholder = "Enter last name",
  emailLabel = "Email ID *",
  emailPlaceholder = "Enter email",
  scopeLabel = "Scope *",
  scopePlaceholder = "Select scope",
  searchTermLabel = "Search an employee (alias) *",
  SearchTermPlaceholder = "Enter alias"
}

export enum editRoleEnums {
  heading = "Edit User Roles",
  subHeading = "This will help you change or edit the user’s role.",
  userNameLabel = "User Name",
  emailLabel = "User Email ID",
  scopeLabel = "Scope",
  scopePlaceholder = "Choose Scope",
}

export enum AddNewMarketOfferingEnums {
  heading = "Edit User Roles",
  subHeading = "This will help you change or edit the user’s role.",
  MOFcodeLabel = "MOF Code",
  MOFLeadLabel = "MOF Lead",
  MOFNameLabel = "MOF Name",
  engagementIdLabel = "Engagement Id",
  engagementHeading = "Engagement - NSR Ranges",
  MoNameExists = "MO Name already exists.",
  MOCodeExists = "MO Code already exists."
  // nsrRanges=["Small","Medium","Large"]
}

export enum editOfferingPortfolioEnums {
  heading = "Edit Offering Portfolio",
  subHeading = "This will help you to edit an offering portfolio in the framework.",
  OPcodeLabel = "OP Code",
  OPNameLabel = "OP Name",
  OPCOOLabel = "OP COO",
  OPDeligateLabel = "OP COO Deligate",
  OPName = ""
}

export enum addOfferingPortfolioEnums {
  heading = "Add Offering Portfolio",
  subHeading = "This will help you to add an new offering portfolio in the framework.",
  OPcodeLabel = "OP Code *",
  OPNameLabel = "Offering Portfolio Name *",
  OPCOOLabel = "OP COO *",
  OPDeligateLabel = "OP COO Deligate",
  OPNameExists = "OP Name already exists.",
  OPCodeExists = "OP Code already exists."
}

export enum addOfferingEnums{
  OfferingCodeLabel = "Offering Code",
  OfferingName = "",
  OfferingNameExists = "Offering Name already exists.",
  OfferingCodeExists = "Offering Code already exists."
}

export enum addMarketOfferingEnums{
  MOCodeLabel = "MO Code"
}

export enum deleteOfferingPortfolioEnums {
  heading = "Are you sure you want to delete Offering Portfolio ",
}

export enum deleteOfferingEnums {
  heading = "Are you sure you want to delete Offering ",
}

export enum deleteUserEnums {
  heading = "Are you sure you want to delete user ",
}

export enum inputValidationError {
  min2Character = "Minimum 2 characters required.",
  max500Characters = "Maximum 500 characters allowed."
}

export enum validateResponse {
  sameName = "with the same name already exists",
  already = "already exists"
}