import React, {ReactNode} from 'react';
import { Spin } from 'antd';
import './Loader.css';

interface iLoaderProps {
	isLoading: boolean;
	size?: "default" | "large" | "small" | undefined;
	children?: ReactNode;
	fullPage?: boolean;
	plainLoader?: boolean;
}

const Loader = ({
	isLoading,
	size = "default",
	children,
	fullPage = true,
	plainLoader,
}: iLoaderProps) => {
	return (
		<>
			{isLoading ? (
				<div className={`${fullPage ? "centerScreen" : ""}`}>
					{!plainLoader && (
						<Spin size={size} spinning={isLoading} data-testid="Spin">
							{children}
						</Spin>
					)}
					{plainLoader && (
						<Spin size={size} spinning={isLoading} data-testid="Spin">
							<></>
						</Spin>
					)}
				</div>
			) : (
				<>{children}</>
			)}
		</>
	);
};

export default Loader;
