export enum chartLabels {
  offeringPortfolioDropdown = "Offering Portfolio",
  rphXLabelValue = "",
  rphYLabelValue = "Blended RPH",
  marginXLabelValue = "$M USD",
  marginYLabelValue = "Margin %",
  pricingSourceNote = "Source:  Approved (Open / Won) CP3 Consulting Pricing Models reflecting primary Market Offering matching the selection above. Questions?  Need tailored benchmarks?  E-mail us at "
}

export enum chartLegendColors {
  "#BAE7FF",
  "#40A9FF",
  "#096DD9",
  "#002766",
}
