import { Button } from "antd";
import React from "react";
import { ButtonProps } from "../../../shared/interfaces/common";

const CommonButton: React.FC<ButtonProps> = ({
  border = "none",
  color = "#007CB0",
  fontColor = "white",
  value,
  height,
  onClick,
  radius,
  width,
  disabled = false,
  className = "",
  id=""
}) => (
  <Button
    data-testid="button-test"
    onClick={onClick}
    style={{
      backgroundColor: (!disabled && color) || "",
      border,
      borderRadius: radius,
      height,
      width,
      color: disabled ? "#4C4E52" : fontColor,
    }}
    disabled={disabled}
    id={id}
    className={className}>
    {value}
  </Button>
);

export default CommonButton;
