export const getSubTitle = (error: string) => {
    switch (error) {
      case "403":
        return "Sorry, you are not authorized to access this page.";
      case "404":
        return "Sorry, the page you visited does not exist.";
      case "500":
        return "Sorry, something went wrong.";
      default:
        return "Something went wrong! Please try again later";
    }
  };