import React from "react";
import { notification } from "antd";

const ErrorMessage = (messageContent: string) => {
  notification.error({
    message: messageContent,
    duration: 2,
    closeIcon: <span></span>,
    style: {
      border: "2px solid #FE4E4D",
      float: "right",
      fontFamily: "Roboto",
      borderRadius: 2,
      fontSize: 14,
      backgroundColor: "#FFF2F1",
      width: "100%",
      padding: "0.5rem 0 0.5rem 0.5rem",
      marginRight: "1rem",
      marginTop: "3rem",
    },
  });
};

export default ErrorMessage;
