import { iDeleteUser } from "../../../shared/interfaces/userManagementInterface";
import { Modal, Typography } from "antd";
import CommonButton from "../../common/input/CommonButton";
import "./EditOffering.css";
import { Endpoints } from "../../../shared/enums/endpoints";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import SuccessMessage from "../../common/input/SuccessMessage";
import ErrorMessage from "../../common/input/ErrorMessage";
import { deleteOfferingEnums } from "../../../shared/enums/userManagementEnum";
import { useEffect } from "react";

const { Title, Text } = Typography;

const DeleteOffering = ({
  deleteModalOpen,
  setDeleteModalOpen,
  setCurrentPage,
  getOfferingData,
  deleteOffering,
}: any) => {
  const deleteOfferingHandler = async () => {
    try {
      const options = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        queryParam: `offering_code=${deleteOffering?.offering_code || 0}` || "",
      };
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.offering}`,
        options
      );
      if (!data?.errMsg) {
        SuccessMessage(`Offering ${deleteOffering.label} deleted successfully.`);
        setCurrentPage(1);
        getOfferingData();
      } else {
        if(data.responseData.message.includes("is still referenced from table")){
          ErrorMessage("Cannot delete an Offering with active Market Offerings under it.");
        }
        else{
          ErrorMessage("Something went wrong. Please try again later");
        }
      }
    } catch (err) {
      ErrorMessage("Something went wrong. Please try again later");
    }
  };
  const handleOk = () => {
    deleteOfferingHandler();
    setDeleteModalOpen(false);
  };

  const handleCancel = () => {
    setDeleteModalOpen(false);
  };
  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [deleteModalOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      setDeleteModalOpen(false);
    }
  };

  return (
    <>
      <Modal
        open={deleteModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        centered
      >
        <Title level={5}>
          {deleteOfferingEnums.heading}
          {deleteOffering?.label || ""}
          {"?"}
        </Title>
        <div className="delete-user-btn-row">
          <div className="delete-user-cancel-btn">
            <CommonButton
              value="Cancel"
              onClick={handleCancel}
              color="#FFFFFF"
              fontColor="rgba(0, 0, 0, 0.85)"
              border="1px solid #D9D9D9"
            />
          </div>
          <CommonButton value="Delete" onClick={handleOk} />
        </div>
      </Modal>
    </>
  );
};

export default DeleteOffering;
