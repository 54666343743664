import { Button, Form, Menu, MenuProps, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import CommonButton from "../../common/input/CommonButton";
import Label from "../../common/input/Label";
import "./EditMarketOffering.css";
import ErrorMessage from "../../common/input/ErrorMessage";
import SuccessMessage from "../../common/input/SuccessMessage";
import TextField from "../../common/input/TextField";
import { ListFormat } from "typescript";
import LeadsDropdown from "../offering/LeadsDropdown";
import UsersDropdown from "../offering/UsersDropdown";
import OfferingDropdown from "../marketOffering/OfferingDropdown";
import DropDown from "../../common/input/DropDown";
import { inputValidationError, validateResponse, AddNewMarketOfferingEnums } from "../../../shared/enums/userManagementEnum";
import MenuItem from "antd/lib/menu/MenuItem";
import { response } from "msw";
// import OpDropdown from "./OpDropdown";
const { Title, Text } = Typography;

const AddMarketOffering = ({
  addMarketOfferingModalOpen,
  setAddMarketOfferingModalOpen,
  marketOfferingData,
  getMarketOfferingData,
  setCurrentPage,
}: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [validateLeadandDelegate, setValidateLeadandDelegate] = useState(false);
  const [validateBulge, setValidateBulge] = useState<boolean>(true);
  const [offering_lead_id, setOffering_lead_id] = useState<string>("");
  const [nsrValidation, setNsrValidation] = useState<boolean>(false);
  const [offering_delegate_id, setOffering_delegate_id] = useState<string>("");
  const [valueChanged, setValueChanged] = useState({
    mo_name: false,
    mo_code: false,
    mo_lead_user: false,
    offering_name: false,
    service_excellence_lead_user: false,
    delivery_excellence_lead_user: false,
    revenue_range_xs: false,
    revenue_range_s: false,
    market_Pricing_Guidance: false,
    revenue_range_m: false,
    revenue_range_l: false,
    emThreshold: false,
    bulge_soc: true,
    bulge_soc_overall: true
  });
  const [validated, setValidated] = useState(false);
  const [leadersData, setLeadersData] = useState<any>([]);
  const [allUsersData, setallUsersData] = useState<any>([]);
  const [offeringData, setOfferingData] = useState<any>([]);

  const [emThresholdData, setemThresholdData] = useState<any>([]);
  const [bulgeOverallTextdisable, setBulgeOverallTextdisable] = useState<boolean>(true);
  const[bulgeValidated, setBulgeValidated] = useState<boolean>(false);
  const [bulgedata, setbulgedata] = useState<any>([{"key":"Bulge Ratio", "label":"Bulge Ratio"}, {"key":"Span of Control", "label":"Span of Control"}]);
  const [marketOfferingAddDetails, setMarketOfferingAddDetails] = useState<any>(
    {
      mo_name: "",
      mo_code: "",
      mo_lead_user: "",
      offering_name: "",
      mo_delegate: "",
      service_excellence_lead_user: "",
      delivery_excellence_lead_user: "",
      revenue_range_xs: "",
      revenue_range_s: "",
      revenue_range_m: "",
      revenue_range_l: "",
      emThreshold: "",
      bulge_soc: "",
      bulge_soc_overall: null
    }
  );

  useEffect(() => {
    if (
			valueChanged.mo_name &&
			marketOfferingAddDetails.mo_name.trim() !== "" &&
			marketOfferingAddDetails.mo_name.trim().length >= 2 &&
			valueChanged.mo_code &&
			marketOfferingAddDetails.mo_code !== "" &&
      marketOfferingAddDetails.mo_code.trim().length <= 8 &&
			valueChanged.mo_lead_user &&
			valueChanged.offering_name &&
			valueChanged.service_excellence_lead_user &&
			valueChanged.delivery_excellence_lead_user &&
			valueChanged.revenue_range_xs &&
			marketOfferingAddDetails.revenue_range_xs !== "" &&
			valueChanged.revenue_range_s &&
			marketOfferingAddDetails.revenue_range_s !== "" &&
			valueChanged.emThreshold &&
			valueChanged.revenue_range_l &&
			marketOfferingAddDetails.revenue_range_l !== "" &&
      offering_lead_id != offering_delegate_id
      && validateBulge == true && marketOfferingAddDetails.offering_name != ""
		) {
			setValidated(true);
		} else {
			setValidated(false);
		}
  }, [valueChanged]);
  
  useEffect(() => {
    callApi();
    callApiOffering();
    callEMThresholdApi();
  }, [addMarketOfferingModalOpen]);

  const options = {
    headers: {}
  }

  const callEMThresholdApi = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}${Endpoints.emThreshold}`, options)
      .then((response: any) => response.json())
      .then((d: any) => {
        const { data } = d;
        const datas = data.map((item: any) => ({
          key: item.engagement_type,
          label: item.engagement_type,
          id: item.id,
        }));
        setemThresholdData(datas);
      });
  };
  const callApiOffering = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}${Endpoints.offering}`, options)
      .then((response: any) => response.json())
      .then((d: any) => {
        const { data } = d;
        const datas = data.map((item: any) => ({
          value: item.key,
          text: item.label,
        }));
        setOfferingData(datas);
      });
  };
  const callApi = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}${Endpoints.user}`, options)
      .then((response: any) => response.json())
      .then((d: any) => {
        const { data } = d;
        const datas = data.map((item: any) => ({
          value: item.id,
          text: item.first_name + " " + item.last_name,
        }));
        setLeadersData(datas);
      });
  };

  const handleOk = () => {
    if ((parseInt(marketOfferingAddDetails.revenue_range_xs) >= parseInt(marketOfferingAddDetails.revenue_range_s)) || 
    parseInt(marketOfferingAddDetails.revenue_range_s) >= parseInt(marketOfferingAddDetails.revenue_range_l)) {
      setNsrValidation(true);
    }
    else {
      setNsrValidation(false);
      addMarketOfferingDetails();
      setCurrentPage(1);
      setValueChanged({
        mo_name: false,
        mo_code: false,
        mo_lead_user: false,
        offering_name: false,
        service_excellence_lead_user: false,
        delivery_excellence_lead_user: false,
        revenue_range_xs: false,
        revenue_range_s: false,
        market_Pricing_Guidance: false,
        revenue_range_m: false,
        revenue_range_l: false,
        emThreshold: false,
        bulge_soc: true,
        bulge_soc_overall: true
      });
      setMarketOfferingAddDetails({
        mo_name: "",
        mo_code: "",
        mo_lead_user: "",
        offering_name: "",
        mo_delegate: "",
        service_excellence_lead_user: "",
        delivery_excellence_lead_user: "",
        revenue_range_xs: "",
        revenue_range_s: "",
        market_Pricing_Guidance: "",
        revenue_range_m: "",
        emThreshold: "",
        revenue_range_l: "",
        bulge_soc: "",
        bulge_soc_overall: null
      });
      setError("");
      setValidated(false);
      setAddMarketOfferingModalOpen(false);
      setLeadersData("");
    }
  };
  const handleCancel = () => {
    setError("");
    setLeadersData("");
    setValueChanged({
      mo_code: false,
      mo_name: false,
      mo_lead_user: false,
      offering_name: false,
      service_excellence_lead_user: false,
      delivery_excellence_lead_user: false,
      revenue_range_xs: false,
      revenue_range_s: false,
      market_Pricing_Guidance: false,
      revenue_range_m: false,
      emThreshold: false,
      revenue_range_l: false,
      bulge_soc: true,
      bulge_soc_overall: true
    });
    setValidated(false);

    setMarketOfferingAddDetails({
      mo_name: "",
      mo_code: "",
      mo_lead_user: "",
      offering_name: "",
      mo_delegate: "",
      service_excellence_lead_user: "",
      delivery_excellence_lead_user: "",
      revenue_range_xs: "",
      revenue_range_s: "",
      market_Pricing_Guidance: "",
      revenue_range_m: "",
      emThreshold: "",
      revenue_range_l: "",
      bulge_soc: "",
      bulge_soc_overall: null
    });
    setAddMarketOfferingModalOpen(false);
  };
  const handleTextInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const re = /^[A-Za-z &,.-]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMarketOfferingAddDetails({
        ...marketOfferingAddDetails,
        [e.target.name]: e.target.value,
      });
      setValueChanged({ ...valueChanged, [e.target.name]: true });
    }
  };

  useEffect(() =>{
    if (marketOfferingAddDetails.revenue_range_xs != "" && marketOfferingAddDetails.revenue_range_s != "" && marketOfferingAddDetails.revenue_range_l) {
      if (!((parseInt(marketOfferingAddDetails.revenue_range_xs) >= parseInt(marketOfferingAddDetails.revenue_range_s)) ||
        parseInt(marketOfferingAddDetails.revenue_range_s) >= parseInt(marketOfferingAddDetails.revenue_range_l))) {
        setNsrValidation(false);
        console.log("false");
      }
    }
  },[marketOfferingAddDetails.revenue_range_xs, marketOfferingAddDetails.revenue_range_s, marketOfferingAddDetails.revenue_range_l])
  useEffect(()=>{
    if(marketOfferingAddDetails.bulge_soc != ""){
      if(marketOfferingAddDetails.bulge_soc_overall){
        setValidateBulge(true);
      }
      else{setValidateBulge(false);}
    }
    setValueChanged({ ...valueChanged, bulge_soc: true });
  },[marketOfferingAddDetails.bulge_soc, marketOfferingAddDetails.bulge_soc_overall])

  const handleNumberInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const re = /^[0-9]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMarketOfferingAddDetails({
        ...marketOfferingAddDetails,
        [e.target.name]: e.target.value,
      });
      setValueChanged({ ...valueChanged, [e.target.name]: true });
    }
  };

  const handleDecimalNumberInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const regex = /^[0-9]+$/;
    if(e.target.value != "."){
      let splitted = e.target.value.split('');
      let isValid = true;
      for(let n of splitted){
        isValid = regex.test(n) || n == ".";
        if (!isValid) {
          break;
        }
      }
      if (isValid) {
        setMarketOfferingAddDetails({
          ...marketOfferingAddDetails,
          [e.target.name]: e.target.value,
        });
        setValueChanged({ ...valueChanged, [e.target.name]: true });
      }
    }
  };

  const setOffering = (val: any) => {
    setValueChanged({ ...valueChanged, offering_name: true });
    setMarketOfferingAddDetails({
      ...marketOfferingAddDetails,
      offering_name: val,
    });
  };
  const setMODelegate = (val: any) => {
    setValueChanged({ ...valueChanged, offering_name: true });
    setMarketOfferingAddDetails({
      ...marketOfferingAddDetails,
      mo_delegate: val,
    });
  };
  const setSalesExcellenceLead = (val: any) => {
    setValueChanged({ ...valueChanged, service_excellence_lead_user: true });
    setMarketOfferingAddDetails({
      ...marketOfferingAddDetails,
      service_excellence_lead_user: val,
    });
  };

  const setDeliveryExcellenceLead = (val: any) => {
    setValueChanged({ ...valueChanged, delivery_excellence_lead_user: true });
    setMarketOfferingAddDetails({
      ...marketOfferingAddDetails,
      delivery_excellence_lead_user: val,
    });
  };
  const setmarketOfferingLead = (val: any) => {
    setValueChanged({ ...valueChanged, mo_lead_user: true });
    setMarketOfferingAddDetails({
      ...marketOfferingAddDetails,
      mo_lead_user: val,
    });
  };
  const setemThreshold = (val: any) => {
    setValueChanged({ ...valueChanged, emThreshold: true });

    setMarketOfferingAddDetails({
      ...marketOfferingAddDetails,
      emThreshold: val,
    });
  };

  const setBulgeSoc = (val: any) => {
    setValueChanged({ ...valueChanged, bulge_soc: true });
    setMarketOfferingAddDetails({
      ...marketOfferingAddDetails,
      bulge_soc: val,
    });
  };

  const getBody = (
    molead: any,
    modelegate: any,
    salesLead: any,
    deliveryLead: any,
    offering: any,
    em: any
  ) => {
    if (modelegate == "") {
      return {
        mo_code: parseInt(marketOfferingAddDetails.mo_code),
        mo_name: marketOfferingAddDetails.mo_name.trim(),
        offering_code: offering,
        mo_lead: molead,
        revenue_range_xs: parseInt(marketOfferingAddDetails?.revenue_range_xs),
        revenue_range_s: parseInt(marketOfferingAddDetails?.revenue_range_s),
        revenue_range_m: parseInt(marketOfferingAddDetails?.revenue_range_l),
        revenue_range_l: parseInt(marketOfferingAddDetails?.revenue_range_l),
        delivery_excellence_lead: deliveryLead,
        service_excellence_lead: salesLead,
        engagement_type: em,
        bulge_soc:marketOfferingAddDetails?.bulge_soc,
        bulge_soc_overall: parseFloat(marketOfferingAddDetails?.bulge_soc_overall)
      };
    } else
      return {
        mo_code: parseInt(marketOfferingAddDetails.mo_code),
        mo_name: marketOfferingAddDetails.mo_name.trim(),
        offering_code: offering,
        mo_lead: molead,
        revenue_range_xs: parseInt(marketOfferingAddDetails?.revenue_range_xs),
        revenue_range_s: parseInt(marketOfferingAddDetails?.revenue_range_s),
        revenue_range_m: parseInt(marketOfferingAddDetails?.revenue_range_l),
        revenue_range_l: parseInt(marketOfferingAddDetails?.revenue_range_l),
        delivery_excellence_lead: deliveryLead,
        service_excellence_lead: salesLead,
        engagement_type: em,
        mo_delegate: modelegate,
        bulge_soc:marketOfferingAddDetails?.bulge_soc,
        bulge_soc_overall: parseFloat(marketOfferingAddDetails?.bulge_soc_overall)
      };
  };

  const addMarketOfferingDetails = async () => {
    const molead = leadersData.find((item: any) => {
      return item.text == marketOfferingAddDetails.mo_lead_user;
    });
    let modelegate: any = "";
    const val = leadersData?.find((item: any) => {
      return item.text == marketOfferingAddDetails?.mo_delegate;
    });
    modelegate = val?.value || "";
    const salesLead = leadersData.find((item: any) => {
      return item.text == marketOfferingAddDetails.service_excellence_lead_user;
    });
    const deliveryLead = leadersData.find((item: any) => {
      return (
        item.text == marketOfferingAddDetails.delivery_excellence_lead_user
      );
    });
    const offering = offeringData.find((item: any) => {
      return item.text == marketOfferingAddDetails.offering_name;
    });
    const em = emThresholdData.find((item: any) => {
      return item.key == marketOfferingAddDetails.emThreshold;
    });
    try {
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(
          getBody(
            molead.value,
            modelegate,
            salesLead.value,
            deliveryLead.value,
            offering.value,
            em.id
          )
        ),
      };
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.marketOffering}`,
        options
      );
      if (!data?.errMsg) {
        if(data.responseData.message.includes(`${validateResponse.sameName}`)){
          ErrorMessage(`${AddNewMarketOfferingEnums.MoNameExists}`);
        }
        else {
          SuccessMessage(`Market Offering ${marketOfferingAddDetails.mo_name} added successfully.`);
          getMarketOfferingData();
        }
      } else {
        if(data.responseData.message.includes(`${validateResponse.already}`)){
          ErrorMessage(`${AddNewMarketOfferingEnums.MOCodeExists}`);
        }
        else{
          ErrorMessage("Something went wrong. Please try again later");
        }
      }
    } catch (err) {
      ErrorMessage("Something went wrong. Please try again later");
    }
  };
  const setOfferingLeadVal = (val: string) =>{
    setOffering_lead_id(val);
    setValidateLeadandDelegate(val == offering_delegate_id ? true: false);
  }

  const setOfferingDelegateVal = (val: string) =>{
    setOffering_delegate_id(val);
    setValidateLeadandDelegate(val == offering_lead_id ? true : false);
  }

  const setDeliveryExcellenceLeaderVal = (val: string) =>{ }

  const setSalesExcellenceLeadVal =(val: string) => { }

  const handleEMDropdownChange: MenuProps["onClick"] = (e) => {
    setemThreshold(e.keyPath[0]);
  };

  const handleBulgeSocChange: MenuProps["onClick"] = (e) => {
    setBulgeSoc(e.keyPath[0]);
    setBulgeOverallTextdisable(false);
  };

  const emMenuItems = (
    <Menu onClick={handleEMDropdownChange} items={emThresholdData} />
  );

const bulge_soc_MenuItems= (
    <Menu onClick={handleBulgeSocChange} items={bulgedata} />
  );

  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [addMarketOfferingModalOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      handleCancel();
    }
  };

  return (
		<>
			<Modal
				open={addMarketOfferingModalOpen}
				closable={false}
				footer={null}
				width={"56.5rem"}
				centered
			>
				<Form
					onFinish={handleOk}
					form={form}
					layout="vertical"
					className="edit-offering-form"
					fields={[
						{
							name: ["mo_name"],
							value: marketOfferingAddDetails.mo_name,
						},
					]}
				>
					<Title level={5}>Add Market Offering</Title>
					<Text>
						This will help you to add a market offering in the framework.
					</Text>
					<div className="admin-offering-input-main">
						<div className="admin-market-offering-row">
							<div className="market-offering-input">
								<Text>MO Code *</Text>
								<TextField
									name="mo_code"
									onChange={handleNumberInputChange}
									value={marketOfferingAddDetails.mo_code}
									className="offering-textfield"
                  maxLength={8}
									// disabled={true}
								/>
							</div>
							<div className="market-offering-input">
								<Form.Item
									label="Market Offering Name *"
									name="mo_name"
									// rules={[
									// 	{
									// 		whitespace: true,
									// 		message: "Display Name cannot be empty.",
									// 	},
									// 	{
									// 		validator: (_, value) =>
									// 			value.trim().length >= 2
									// 				? Promise.resolve()
									// 				: Promise.reject(
									// 						new Error(inputValidationError.min2Character)
									// 				  ),
									// 	},
									// 	{
									// 		max: 500,
									// 		message: inputValidationError.max500Characters,
									// 		type: "string",
									// 	},
									// ]}
								>
									<TextField
										name="mo_name"
										onChange={handleTextInputChange}
										value={marketOfferingAddDetails.mo_name}
										className="offering-textfield"
                    maxLength={100}
										// disabled={true}
									/>
								</Form.Item>
							</div>
							<div className="market-offering-input">
								<Text>Market Offering Lead *</Text>
								<div className="mo_admin-dropdown">
									<LeadsDropdown
										setInitialVal={setmarketOfferingLead}
										style={{ width: "100%" }}
										initialVal={marketOfferingAddDetails.mo_lead_user}
                    setSelectedVal={setOfferingLeadVal}
									/>
								</div>
							</div>
						</div>
						<div className="admin-market-offering-row">
							<div className="market-offering-input">
								<Text>Sales Excellence Leader *</Text>
								<div className="mo_admin-dropdown">
									<UsersDropdown
										setInitialVal={setSalesExcellenceLead}
										style={{ width: "100%" }}
										initialVal={
											marketOfferingAddDetails.service_excellence_lead_user
										}
                    setSelectedVal={setSalesExcellenceLeadVal}
									/>
								</div>
							</div>
							<div className="market-offering-input">
								<Text>Delivery Excellence Leader *</Text>
								<div className="mo_admin-dropdown">
                <UsersDropdown
										setInitialVal={setDeliveryExcellenceLead}
										style={{ width: "100%" }}
										initialVal={
											marketOfferingAddDetails.delivery_excellence_lead_user
										}
                    setSelectedVal={setDeliveryExcellenceLeaderVal}
									/>
								</div>
							</div>

							<div className="market-offering-input">
								<Text>Offering *</Text>
								<div className="mo_admin-dropdown">
									<OfferingDropdown
										style={{ width: "100%" }}
										setInitialVal={setOffering}
										initialVal={marketOfferingAddDetails.offering_name}
									/>
								</div>
							</div>
						</div>
						<div className="admin-market-offering-row">
							<div className="market-offering-input">
								<Text>EM Threshold *</Text>
								<div className="mo_admin-add-dropdown">
									<DropDown
										menu={emMenuItems}
										value={
											marketOfferingAddDetails.emThreshold || "Select Threshold"
										}
										placeholder={""}
									/>
								</div>
							</div>
							<div className="market-offering-input">
								<Text>Market Offering Delegate</Text>
								<div className="mo_admin-dropdown">
									<LeadsDropdown
										style={{ width: "100%" }}
										setInitialVal={setMODelegate}
										initialVal={marketOfferingAddDetails.mo_delegate}
                    setSelectedVal={setOfferingDelegateVal}
									/>
                  {validateLeadandDelegate == true && (
                  <Label style={{ color: "#FF0000" }}
              placeholder={"Market Offering Lead and Delegate cannot be same."} />
                )}
								</div>
							</div>
						</div>
            <div className="admin-market-offering-row">
							<div className="market-offering-input">
								<Text>Bulge Ratio/Span of Control</Text>
								<div className="mo_admin-add-dropdown">
									<DropDown
										menu={bulge_soc_MenuItems}
										value={
											marketOfferingAddDetails.bulge_soc || "Select"
										}
										placeholder={""}
									/>
								</div>
							</div>
						<div className="market-offering-input">
								<Text>Bulge/SOC Overall</Text>
                <TextField
										name="bulge_soc_overall"
										onChange={handleDecimalNumberInputChange}
										value={marketOfferingAddDetails.bulge_soc_overall}
										className="offering-textfield"
                    maxLength={8}
										disabled={bulgeOverallTextdisable}
									/>
							</div>
						</div>
						<div className="admin-market-offering-row">
							<div className="market-offering-text-heading-2">
								Tool Setting - NSR Range
							</div>
						</div>
						<div className="admin-market-offering-row">
							<div className="market-offering-input-nsr">
								<div className="nsr-input">
									<Text>Small *</Text>
									<TextField
										name="revenue_range_xs"
										onChange={handleNumberInputChange}
										value={marketOfferingAddDetails.revenue_range_xs}
										className="offering-textfield"
                    maxLength={8}
										// disabled={true}
									/>
								</div>
								<div className="nsr-input-text">
									<Text>to</Text>
								</div>
								<div className="nsr-input-to">
									<TextField
										name="revenue_range_s"
										onChange={handleNumberInputChange}
										value={marketOfferingAddDetails.revenue_range_s}
										className="offering-textfield"
                    maxLength={8}
										//  // disabled={true}
									/>
								</div>
							</div>
						</div>
						<div className="admin-market-offering-textarea">
							<div className="market-offering-input-nsr">
								<div className="nsr-input">
									<Text>Medium *</Text>
									<TextField
										name="revenue_range_s"
										onChange={handleNumberInputChange}
										value={marketOfferingAddDetails.revenue_range_s}
										className="offering-textfield"
										disabled={true}
									/>
								</div>
								<div className="nsr-input-text">
									<Text>to</Text>
								</div>
								<div className="nsr-input-to">
									<TextField
										name="revenue_range_l"
										onChange={handleNumberInputChange}
										value={marketOfferingAddDetails.revenue_range_l}
										className="offering-textfield"
                    maxLength={8}
										// disabled={true}
									/>
								</div>
								<div className="nsr-input">
									<Text>Large *</Text>
									<TextField
										name="revenue_range_l"
										onChange={handleNumberInputChange}
										value={marketOfferingAddDetails.revenue_range_l}
										className="offering-textfield"
										disabled={true}
									/>
								</div>
								<div className="nsr-input-text-for-large">
									<Text>and more</Text>
								</div>
							</div>
              {nsrValidation == true && (
                  <Label style={{ color: "#FF0000" }}
              placeholder={"Final NSR Range must be greater than Start NSR Range."} />
                )}
						</div>
					</div>
					<div className="admin-offering-buttons">
						<CommonButton
							value="Cancel"
							onClick={handleCancel}
							color="#FFFFFF"
							fontColor="rgba(0, 0, 0, 0.85)"
							border="1px solid #D9D9D9"
						/>
						<CommonButton
							value="Add Market Offering"
							onClick={handleOk}
							disabled={!validated}
							className="save-button"
						/>
					</div>
				</Form>
			</Modal>
		</>
	);
};

export default AddMarketOffering;
