import { Modal } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import CommonButton from "../../common/input/CommonButton";
interface iDeleteMO {
  deleteModalOpen: boolean;
  setDeleteModalOpen: Function;
  moToBeDeleted: {
    id: number;
    mo_name: string;
  } | null;
  deleteMarketOfferingApiResponse: Function;
}
const DeleteMo = ({
  deleteModalOpen,
  setDeleteModalOpen,
  moToBeDeleted,
  deleteMarketOfferingApiResponse,
}: iDeleteMO) => {
  const handleOk = () => {
    deleteMarketOfferingApiResponse();
    setDeleteModalOpen(false);
  };
  const handleCancel = () => {
    setDeleteModalOpen(false);
  };
  return (
    <>
      <Modal
        open={deleteModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        centered
      >
        <Title level={5}>
          Are you sure you want to delete Market Offering {moToBeDeleted?.mo_name}?
        </Title>
        <div className="delete-user-btn-row">
          <div className="delete-user-cancel-btn">
            <CommonButton
              value="Cancel"
              onClick={handleCancel}
              color="#FFFFFF"
              fontColor="rgba(0, 0, 0, 0.85)"
              border="1px solid #D9D9D9"
            />
          </div>
          <CommonButton value="Delete" onClick={handleOk} />
        </div>
      </Modal>
    </>
  );
};

export default DeleteMo;
