import { Pagination, Form, Table, Button, MenuProps, Menu } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import TextField from "../../common/input/TextField";
import React, { useEffect, useState, useRef } from "react";
import {
  fetchAPI,
  getErrorResponseStatus,
} from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import ErrorPage from "../../common/errorPage/ErrorPage";
import CommonButton from "../../common/input/CommonButton";
import "../UserManagement/UserManagement.css";
import DropDown from "../../common/input/DropDown";
import HighlightTable from '../../common/highlighter/HighlightTable';
import EditOffering from "./EditOffering";
import AddOffering from "./AddOffering";
import DeleteOffering from "./DeleteOffering";
import "../marketOffering/MarketOfferingManagement.css";

const OfferingManagement = () => {
  
  const [error, setError] = useState("");
  const [analyticLoaded, setAnalyticLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [offeringTableData, setOfferingTableData] = useState([]);
  const [addOfferingModalOpen, setAddOfferingModalOpen] = useState(false);
  const [editOfferingModalOpen, setEditOfferingModalOpen] = useState(false);
  const [toEditOfferingData, setToEditOfferingData] = useState(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [deleteOffering, setDeleteOffering] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [searchOfferingParam, setSearchOfferingParam] = useState("All");
  const [searchOfferingValue, setSearchOfferingValue] = useState("");
  const [enableBtn, setEnableBtn] = useState(false);
  useEffect(() => {
    getOfferingData();
  }, []);

  useEffect(() =>{
    if(analyticLoaded == false){
      window.analyticsDataLayer = {
        pageInfo:{
          pageName:"Offering",
          userID: sessionStorage.getItem('userName'),
          applicationName: "Strategic Pricing Portal",
          userType: sessionStorage.getItem("userType"),
        }
      };
      if(typeof window._satellite !== 'undefined'){
        //window._satellite.track("pageView");
      }
      setAnalyticLoaded(true);
      sessionStorage.setItem("userManagementCall", "false");
      sessionStorage.setItem("dashboardCall", "false");
      sessionStorage.setItem("adminCall", "false");
    }
  })

  useEffect(() => {
    if (!searchOfferingParam && !searchOfferingValue) setEnableBtn(false);
    else if (!searchOfferingParam && searchOfferingValue) setEnableBtn(false);
    else if (searchOfferingParam === "All") {
      setEnableBtn(true);
    } else {
      if (searchOfferingValue.trim().length) setEnableBtn(true);
      else setEnableBtn(false);
    }
  }, [searchOfferingParam, searchOfferingValue]);
  const handleEdit = (val: any) => {
    //here use val.id for getting data
    setEditOfferingModalOpen(true);
    setToEditOfferingData(val);
  };
  const handleDelete = (val: any) => {
    //here use val.id for getting data
    setDeleteOffering(val);
    setDeleteModalOpen(true);
  };
  const handleDropdownChange: MenuProps["onClick"] = (e) => {
    setSearchOfferingParam(e.keyPath[0]);
    if (e.keyPath[0] == "All") {
      setCurrentPage(1);
      getOfferingData();
    }
    else{
      setSearchOfferingValue("");
    }
  };

  useEffect(() =>{
    if(searchOfferingValue === "" && loading===false)
    {
      handleSearchButton();
    }
  }, [searchOfferingValue])

  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchOfferingValue(e.target.value);
  };
  const offeringsearchFilterMenuItems = (
    <Menu
      onClick={handleDropdownChange}
      items={[
        {
          label: "All",
          key: "All",
        },
        {
          label: "Offering",
          key: "Offering",
        },
        {
          label: "OP Code",
          key: "OP Code",
        },
        {
          label: "Offering Code",
          key: "Offering Code",
        },
      ]}
    />
  );
  const columns = [
    {
      title: "Offering Code",
      dataIndex: "offering_code",
    },
    {
      title: "Offering",
      dataIndex: "offering_name",
    },
    {
      title: "Offering Portfolio",
      dataIndex: ["offering_portfolio", "op_name"],
    },
    {
      title: "Offering Lead",
      dataIndex: "offering_lead_user_name",
      render: (_: any, record: any) =>
        `${record.offering_lead_user?.first_name || ""} ${
          record.offering_lead_user?.last_name || ""
        }`,
    },
    {
      title: "Offering Delegate",
      dataIndex: "offering_delegate_user",
      render: (_: any, record: any) =>
        `${record.offering_delegate_user?.first_name || ""} ${
          record.offering_delegate_user?.last_name || ""
        }`,
    },
    {
      title: "Action",
      width: 100,
      dataIndex: "action",
      render: (_: any, record: any) => (
        <div className="admin-action-area">
          <a onClick={() => handleEdit(record)}>
            <EditOutlined style={{ fontSize: "18px", color: "#007CB0" }} />
          </a>
          <a onClick={() => handleDelete(record)}>
            <DeleteOutlined style={{ fontSize: "18px", color: "#DA291C" }} />
          </a>
        </div>
      ),
    },
  ];

  const handleSearchButton = () => {
    setCurrentPage(1);
    getOfferingData();
  }

  const handleSearch = () => {
    if(loading == false){
      if (searchOfferingParam === "All") {
        setCurrentPage(1);
        getOfferingData();
        return;
      }
      if (!searchOfferingParam || !searchOfferingValue || !enableBtn) return;
      setCurrentPage(1);
      let searchQP = "";
      if (searchOfferingParam === "Offering")
        searchQP = `offering_name=${encodeURIComponent(
          searchOfferingValue?.trim()
        )}`;
      else if (searchOfferingParam === "OP Code")
        searchQP = `op_code=${encodeURIComponent(
          searchOfferingValue.trim().replace(/\s\s+/g, " ")
        )}`;
      else if (searchOfferingParam === "Offering Code")
        searchQP = `offering_code=${encodeURIComponent(
          searchOfferingValue.trim().replace(/\s\s+/g, " ")
        )}`;
      fetchOfferingApiResponse(1, searchQP);
    }
  };
  const fetchOfferingApiResponse = async (
    currentPage: number = 1,
    params: string
  ) => {
    setLoading(true);
    try {
      setOfferingTableData([]);
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.offering}`,
        {
          method: "GET",
          queryParam: `page=${currentPage}${params ? "&" + params : ""}`,
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setOfferingTableData(data?.responseData.data);
          if (currentPage == 1) {
            setTotalPage(data?.responseData?.message.total_records);
          }
        } else {
          setOfferingTableData([]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.log("Res Status", data?.responseStatus);
        setError(data.responseStatus);
      }

    } catch (err) {
      setLoading(false);
      setError("Error");
    }

  };

  const getOfferingData = async (currentPage: number = 1) => {
    setLoading(true);
    let searchQP = "";
    try {
      setOfferingTableData([]);
      searchQP = `${encodeURIComponent(
        searchOfferingValue?.trim()
      )}`;
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.offering}`,
        {
          method: "GET",
          queryParam: `page=${currentPage}&contains=${searchQP}`,
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setOfferingTableData(data?.responseData.data);
          if (currentPage == 1) {
            setTotalPage(data?.responseData?.message.total_records);

          }
        } else {
          setOfferingTableData([]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.log("Res Status", data?.responseStatus);
        setError(data.responseStatus);
      }

    } catch (err) {
      setLoading(false);
      setError("Error");
    }

  };
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    getOfferingData(pageNumber);
  };
  return (
    <Form onFinish={handleSearch}>
      {error === "" ? (
        <>
          {addOfferingModalOpen && (
            <AddOffering
              addOfferingModalOpen={addOfferingModalOpen}
              setAddOfferingModalOpen={setAddOfferingModalOpen}
              offeringData={toEditOfferingData}
              getOfferingData={getOfferingData}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
          {editOfferingModalOpen && (<EditOffering
            editOfferingModalOpen={editOfferingModalOpen}
            setEditOfferingModalOpen={setEditOfferingModalOpen}
            offeringData={toEditOfferingData}
            getOfferingData={getOfferingData}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />)}
          <DeleteOffering
            deleteModalOpen={deleteModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            setCurrentPage={setCurrentPage}
            getOfferingData={getOfferingData}
            deleteOffering={deleteOffering}
          />
          <>
            <div className="user-main">
              <div className="label">
                {/* <div className="mo-admin-header-filter">
                  <DropDown
                    value={searchOfferingParam || "Search Using"}
                    menu={offeringsearchFilterMenuItems}
                    placeholder=""
                  />
                </div> */}
                <div className="mo-admin-header-search">
                  <TextField
                    onChange={handleTextFieldChange}
                    value={searchOfferingValue}
                    disabled={!searchOfferingParam}
                    name="search"
                    style={{ width: '30em' }}
                    placeholder="Search by Offering Code, Offering, Offering Portfolio, Offering Lead and Offering Delegate."
                    className="mo-admin-header-search-textfield textfield"
                  />
                  <CommonButton id="btnOfferingSearch"
                    onClick={handleSearch} value={"Search"}
                  ></CommonButton>
                </div>
              </div>
              <CommonButton
                onClick={() => setAddOfferingModalOpen(true)}
                value="Add Offering"
              ></CommonButton>
            </div>
            <>
              <div className="search-content">
                <HighlightTable columns={columns} pagination={false}
                loading={loading} tableData={offeringTableData} searchValue={searchOfferingValue} startPosition={6}/>
              {/* <Table id="tblData"
                columns={columns}
                dataSource={offeringTableData}
                pagination={false}
                loading={loading}
              /> */}

              {offeringTableData.length > 0 ?
              <Pagination
                className="user-paginantion"
                onChange={handlePagination}
                defaultCurrent={currentPage}
                current={currentPage}
                total={totalPage}
              /> : ""}
              </div>
            </>
          </>
        </>
      ) : (
        <ErrorPage responseStatus={getErrorResponseStatus(error)} />
      )}
    </Form>
  );
};

export default OfferingManagement;
