import { Button, Result } from "antd";
import { ErrorPageProps } from "../../../shared/interfaces/common";
import { getSubTitle } from "../../../shared/utils/errorPageUtil";


const ErrorPage = ({ responseStatus, isServiceFailure }: ErrorPageProps) => (
	<Result
		data-testid="errorPage-test"
		status={responseStatus}
		title=""
		subTitle={getSubTitle(responseStatus)}
		extra={ isServiceFailure ?
			<Button
				style={{ background: "#007CB0", color: "white" }}
				onClick={() =>
					window.location.pathname == "/error"
						? window.location.href = "/"
						: window.location.reload()
				}
			>
				Click to reload!
			</Button> : <></>
		}
	/>
);

export default ErrorPage;
