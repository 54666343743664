export enum guidanceEnums{
    heading = "Commercial and Pricing Guidance",
    moLead = "Market Offering Lead",
    targetRph = "Target RPH: ",
    commercialGuidance = "Commercial Strategy: ",
    noGuidance = "No Guidance",
    saleExcellence = "Sales Excellence Lead",
    deliveryExcellence = "Delivery Excellence Lead",
}

export enum addGuidanceEnums{
    addGuidanceHeading = "Add Guidance for",
    updateGuidanceHeading = "Update Guidance for",
    taregtRphLabel = "Target RPH",
    commercialGuidance = "Commercial Strategy",
    guidance = "Leadership Guidance",
    inputPlaceholder ="Enter here",
    updateMessage = "Guidance Updated Successfully.",
    addMessage = "Guidance Added Successfully.",
}
